import { REACT_BASE_LOCAL_URL } from "../../config";

export const handleGetTable = async ({
  DB_NAME,
  TABLE,
  sinceTime,
  profiling = false,
  monitoring = false,
  partitioned = true,
  checkTimeScale = "daily",
}) => {
  const apiURL = `${REACT_BASE_LOCAL_URL}/api/connections/${DB_NAME}/schemas/public/tables/${TABLE}/status?since=${sinceTime}&profiling=${profiling}&monitoring=${monitoring}&partitioned=${partitioned}&checkTimeScale=${checkTimeScale}`;

  try {
    const response = await fetch(apiURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching table:", error);
  }
};
