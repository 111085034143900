import "./SwitchTabFive.css";
import { useState } from "react";
import DashboardEmbed from './DashboardEmbed'; 
import { FaFolder } from 'react-icons/fa'; 

export default function SwitchTabFive() {
  const [selectedMainTab, setSelectedMainTab] = useState(null);
  const [selectedSubTab, setSelectedSubTab] = useState(null);
  const [selectedSubSubTab, setSelectedSubSubTab] = useState(null);
  const [selectedSubSubSubTab, setSelectedSubSubSubTab] = useState(null);

  const handleMainTabClick = (tab) => {
    setSelectedMainTab(tab === selectedMainTab ? null : tab); 
    setSelectedSubTab(null); 
    setSelectedSubSubTab(null); 
    setSelectedSubSubSubTab(null); 
  };

  const handleSubTabClick = (tab) => {
    setSelectedSubTab(tab === selectedSubTab ? null : tab); 
    setSelectedSubSubTab(null); 
    setSelectedSubSubSubTab(null); 
  };

  const handleSubSubTabClick = (tab) => {
    setSelectedSubSubTab(tab === selectedSubSubTab ? null : tab); 
    setSelectedSubSubSubTab(null); 
  };

  const handleSubSubSubClick = (tab) => {
    setSelectedSubSubSubTab(tab === selectedSubSubSubTab ? null : tab); 
  };

  return (
    <div className="main-container"> 
      <div className="tab-list"> 
        <div className="main-heading" onClick={() => handleMainTabClick('Profiling')}>
          <FaFolder style={{ marginRight: '8px' }} /> Profiling
        </div>

        {selectedMainTab === 'Profiling' && (
          <>
            <div className="sub-heading" onClick={() => handleSubTabClick('Table Profiling Status')}>
              Table Profiling Status
            </div>

            {selectedSubTab === 'Table Profiling Status' && (
              <div className="sub-sub-heading">
                <div onClick={() => handleSubSubSubClick('Table Profiling Status Details')}>
                  Table Profiling Status
                </div>
                <div onClick={() => handleSubSubSubClick('Column Profiling Status')}>
                  Column Profiling Status
                </div>
              </div>
            )}
            <div className="sub-heading" onClick={() => handleSubTabClick('Data Profiling Issues Count')}>
              Data Profiling Issues Count
            </div>
            {selectedSubTab === 'Data Profiling Issues Count' && (
              <>
                <div className="sub-sub-heading" onClick={() => handleSubSubSubClick('Data Quality Executed Checks per Table')}>
                  Data Quality Executed Checks per Table
                </div>
                <div className="sub-sub-heading" onClick={() => handleSubSubSubClick('Profiling Issues Count per Check')}>
                  Profiling Issues Count per Check
                </div>
              </>
            )}
               <div className="sub-heading" onClick={() => handleSubTabClick('Data Quality Dimensions')}>
              Data Quality Dimensions
            </div>
            {selectedSubTab === 'Data Quality Dimensions' && (
              <>
                <div className="sub-sub-heading" onClick={() => handleSubSubTabClick('Availability')}>
                  Availability
                </div>
                {selectedSubSubTab === 'Availability' && (
                  <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Current Table Availability')}>
                    Current Table Availability
                  </div>
                )}

                <div className="sub-sub-heading" onClick={() => handleSubSubTabClick('Completeness')}>
                  Completeness
                </div>
                {selectedSubSubTab === 'Completeness' && (
                  <>
                    <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Current Completeness Issues')}>
                      Current Completeness Issues on Columns
                    </div>
                    <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Incomplete Columns')}>
                      Incomplete Columns with Null Values - Counts
                    </div>
                    <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Empty Columns')}>
                      Empty Columns
                    </div>
                  </>
                )}

                <div className="sub-sub-heading" onClick={() => handleSubSubTabClick('Accuracy')}>
                  Accuracy
                </div>
                {selectedSubSubTab === 'Accuracy' && (
                  <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Table Comparison')}>
                    Table Comparison
                  </div>
                )}

                <div className="sub-sub-heading" onClick={() => handleSubSubTabClick('Timeliness')}>
                  Timeliness
                </div>
                {selectedSubSubTab === 'Timeliness' && (
                  <>
                    <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Current Timeliness Issues')}>
                      Current Timeliness Issues
                    </div>
                    <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Table Freshness - Current Data')}>
                      Table Freshness - Tables with the Most Current Data
                    </div>
                    <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Table Freshness - Oldest Data')}>
                      Table Freshness - Tables with the Oldest Data
                    </div>
                  </>
                )}

                <div className="sub-sub-heading" onClick={() => handleSubSubTabClick('Validity')}>
                  Validity
                </div>
                {selectedSubSubTab === 'Validity' && (
                  <div className="sub-sub-sub-heading" onClick={() => handleSubSubSubClick('Current Validity Issues')}>
                    Current Validity Issues on Columns
                  </div>
                )}
              </>
            )}
            <div className="sub-heading" onClick={() => handleSubTabClick('Profiling Data Quality KPIs')}>
              Profiling Data Quality KPIs
            </div>

            {selectedSubTab === 'Profiling Data Quality KPIs' && (
              <div className="sub-sub-heading">
                <div onClick={() => handleSubSubSubClick('Profiling KPIs Scorecard')}>
                  Profiling KPIs Scorecard - Summary
                </div>
                <div onClick={() => handleSubSubSubClick('Profiling KPIs per Table')}>
                  Profiling KPIs per Table - Summary
                </div>
                <div onClick={() => handleSubSubSubClick('KPIs Current vs Previous Month')}>
                  KPIs Current vs Previous Month
                </div>
              </div>
            )}
            
            <div className="sub-heading" onClick={() => handleSubTabClick('Check Results')}>
              Check Results
            </div>
            {selectedSubTab === 'Check Results' && (
              <>
                <div className="sub-sub-heading" onClick={() => handleSubSubSubClick('Current Data Quality Check Results')}>
                  Current Data Quality Check Results
                </div>
                <div className="sub-sub-heading" onClick={() => handleSubSubSubClick('History of Data Quality Check Results')}>
                  History of Data Quality Check Results
                </div>
              </>
            )}
            <div className="sub-heading" onClick={() => handleSubTabClick('Volume')}>
              Volume
            </div>

            {selectedSubTab === 'Volume' && (
              <div className="sub-sub-heading">
                <div onClick={() => handleSubSubSubClick('Largest Tables')}>
                  Largest Tables by Number of Rows
                </div>
                <div onClick={() => handleSubSubSubClick('Empty or Small Tables')}>
                  Empty or Too Small Tables
                </div>
              </div>
            )}

            <div className="sub-heading" onClick={() => handleSubTabClick('PII')}>
              PII
            </div>

            {selectedSubTab === 'PII' && (
              <div className="sub-sub-heading">
                <div onClick={() => handleSubSubSubClick('PII Data Detected')}>
                  PII Data Detected
                </div>
                <div onClick={() => handleSubSubSubClick('History of PII Issues')}>
                  History of PII Issues
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="content"> 
        {selectedSubSubSubTab === 'Table Profiling Status Details' && (
          <DashboardEmbed dashboardId="a856d144-bd93-45f4-bffc-e0eec167cd76" />
        )}
        {selectedSubSubSubTab === 'Column Profiling Status' && (
          <DashboardEmbed dashboardId="a626b486-b575-4c67-b2af-3e9be27c476a" />
        )}
        {selectedSubSubSubTab === 'Profiling KPIs Scorecard' && (
          <DashboardEmbed dashboardId="79d42c69-3852-4668-a041-e63575216798" />
        )}
        {selectedSubSubSubTab === 'Profiling KPIs per Table' && (
          <DashboardEmbed dashboardId="a08df29a-42c7-40bc-a6c4-93cd52dde3dd" />
        )}
        {selectedSubSubSubTab === 'Current Timeliness Issues' && (
          <DashboardEmbed dashboardId="36463ebc-d194-4962-8489-01c4d0dcad94" />
        )}
        {selectedSubSubSubTab === 'Current Table Availability' && (
          <DashboardEmbed dashboardId="de830683-d64a-4c26-904e-b344af3bd9b5" />
        )}
        {selectedSubSubSubTab === 'Table Freshness - Current Data' && (
          <DashboardEmbed dashboardId="dashboard_id" />
        )}
        {selectedSubSubSubTab === 'Table Freshness - Oldest Data' && (
          <DashboardEmbed dashboardId="dashboard_id" />
        )}
        {selectedSubSubSubTab === 'Current Validity Issues' && (
          <DashboardEmbed dashboardId="849be01f-8d28-4375-9fa9-d6ce4b44eae0" />
        )}
        {selectedSubSubSubTab === 'Current Completeness Issues' && (
          <DashboardEmbed dashboardId="0eceea59-c914-4136-8f71-01d74fc408ca" />
        )}
        {selectedSubSubSubTab === 'Incomplete Columns' && (
          <DashboardEmbed dashboardId="fb7cf7d9-9ed5-4c8d-8f1d-934df06e024e" />
        )}
        {selectedSubSubSubTab === 'Empty Columns' && (
          <DashboardEmbed dashboardId="id_empty_columns" />
        )}
        {selectedSubSubSubTab === 'Largest Tables' && (
          <DashboardEmbed dashboardId="e20134f2-4d0b-4721-a3c1-34443c8b0b73" />
        )}
        {selectedSubSubSubTab === 'Empty or Small Tables' && (
          <DashboardEmbed dashboardId="c0152525-d070-4a87-9078-6cc8f5240ff3" />
        )}
        {selectedSubSubSubTab === 'PII Data Detected' && (
          <DashboardEmbed dashboardId="4b83c9ef-e7fd-44f8-8311-524f51a428b6" />
        )}
        {selectedSubSubSubTab === 'Data Quality Executed Checks per Table' && (
          <DashboardEmbed dashboardId="e5f5949e-8816-4bd7-b865-95597242f14b" />
        )}
        {selectedSubSubSubTab === 'Profiling Issues Count per Check' && (
          <DashboardEmbed dashboardId="637af1ca-cc75-4137-b87a-327207fb1324" />
        )}
        {selectedSubSubSubTab === 'KPIs Current vs Previous Month' && (
          <DashboardEmbed dashboardId="f7507321-344a-4d94-a937-07d1db6a2b68" />
        )}
        {selectedSubSubSubTab === 'Current Data Quality Check Results' && (
          <DashboardEmbed dashboardId="cb9020ae6-1438-4016-ac32-49c12d0a6295" />
        )}
        {selectedSubSubSubTab === 'History of Data Quality Check Results' && (
          <DashboardEmbed dashboardId="ab57db9b-e4ea-4ab2-b8d6-8bfa9e6d1e6b" />
        )}
        {selectedSubSubSubTab === 'History of PII Issues' && (
          <DashboardEmbed dashboardId="2a6dd88e-312f-4e91-b363-7aeefcc5a42d" />
        )}
         {selectedSubSubSubTab === 'Table Comparison' && (
          <DashboardEmbed dashboardId="691b4325-d175-48dc-bbdc-9417db0666e0" /> 
        )}
      </div>
    </div>
  );
}
