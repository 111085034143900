import { useState } from "react";
import "./SwitchTabTwo.css";
import columnIcon from "./images/Icon(6).svg";
import startIcon from "./images/star-icon.svg";
import { useEffect } from "react";
import axios from "axios";
import LoadingBar from "../../LoadingBar/LoadingBar";
import { useParams } from "react-router-dom";

export default function SwitchTabTwo() {
  // const [data, setData] = useState(null);
  const [actual_data, setActual_data] = useState();
  const [sugg, setSugg] = useState();
  // const [otherdataun, setOtherdataun] = useState();
  const tokenStr = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [colJustification, setColJustification] = useState();
  const params = useParams();
  console.log(params);
  const fetchData = async () => {
    setLoading(true);
    await axios
      .post(
        `https://data-quality-backend.lab.neuralcompany.team/store_business_terms/${params.file}`,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      )
      .then((response) => {
        console.log(response.data);
        setActual_data(response.data.data.actual_data);
        setSugg(response.data.data.suggested_response);
        // setOtherdataun(response.data.data.other[0]);
        setColJustification(response.data.data);

        console.log(response.data.data);
        // console.log(response.data.business_terms[10]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="tab-content-two-wrapper">
      {actual_data ? (
        <div>
          <div className="tab-content-two-header-items">
            <img src={columnIcon} alt="" />
            <p>
              <span>{actual_data && actual_data.length} </span> Columns
            </p>
          </div>
          <div className="tab-content-two">
            <div className="tab-content-two-left-wrapper">
              <div className="tab-content-two-left">
                <p>Columns</p>
                <p>Logical Type</p>
              </div>
              {loading ? (
                <div className="loading"> Loading ... </div>
              ) : (
                actual_data &&
                actual_data?.map((value, index) => (
                  <div key={index} className="left-table-data">
                    <div className="left-table-row">
                      <div>{value.column_name}</div>
                      <div>{value.logical_type}</div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="tab-content-two-right-wrapper">
              <div className="tab-content-two-right-heading">
                <p>Ai Recommendations</p>
                <img src={startIcon} alt="icon" />
              </div>
              <div className="tab-content-two-right-inner-table">
                <div className="tab-content-two-right">
                  <p>columns</p>
                  <p>Description</p>
                  <p>Sample Data</p>
                </div>
                {loading ? (
                  <div className="loading"> Loading ... </div>
                ) : (
                  sugg &&
                  sugg.map((value, index) => (
                    <div key={index} className="right-table-data">
                      <div className="right-table-row">
                        <div>{value.business_col_name}</div>
                        <div>{value.desc}</div>
                        <div>{value.sample_data}</div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="switch-tab-two-others">
            <div className="tab-two-others-heading">Others</div>
            <div className="tab-two-other-content">
              <div>Unwanted Columns : </div>
              {/* {colJustification.other[0] ? (
                <>{colJustification.other[0].unwanted_columns}</>
              ) : (
                <>{colJustification.other.unwanted_columns[0]}</>
              )} */}
              <div>None</div>
            </div>
            <div className="tab-two-other-content">
              <div>Columns Justification : </div>
              {colJustification.other[0] ? (
                <>{colJustification.other[0].columns_justifications}</>
              ) : (
                <>
                  {Object.keys(
                    colJustification.other.columns_justifications
                  ).map((key, index) => (
                    <div key={index}>
                      {colJustification.other.columns_justifications[key]}
                    </div>
                  ))}
                </>
              )}
              {/* <div>{colJustification?.columns_justifications}</div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="switch-tab-loading-bar">
          <LoadingBar />
        </div>
      )}
    </div>
  );
}

// import { useState } from "react";
// import "./SwitchTabTwo.css";
// import columnIcon from "./images/Icon(6).svg";
// import startIcon from "./images/star-icon.svg";
// import { useEffect } from "react";
// import axios from "axios";
// import LoadingBar from "../../LoadingBar/LoadingBar";
// import { useParams } from "react-router-dom";

// export default function SwitchTabTwo() {
//   // const [data, setData] = useState(null);
//   const [actual_data, setActual_data] = useState();
//   const [sugg, setSugg] = useState();
//   // const [otherdataun, setOtherdataun] = useState();
//   const tokenStr = localStorage.getItem("accessToken");
//   const [loading, setLoading] = useState(false);
//   const [colJustification, setColJustification] = useState();
//   const params = useParams();
//   console.log(params);
//   const fetchData = async () => {
//     setLoading(true);
//     await axios
//       .post(
//         `https://data-quality-backend.lab.neuralcompany.team/store_business_terms/${params.file}`,
//         {},
//         {
//           headers: { Authorization: `Bearer ${tokenStr}` },
//         }
//       )
//       .then((response) => {
//         console.log(response.data);
//         setActual_data(response.data.data.actual_data);
//         setSugg(response.data.data.suggested_response);
//         // setOtherdataun(response.data.data.other[0]);
//         setColJustification(response.data.data);

//         console.log(response.data.data);
//         // console.log(response.data.business_terms[10]);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   return (
//     <div className="tab-content-two-wrapper">
//       {actual_data ? (
//         <div>
//           <div className="tab-content-two-header-items">
//             <img src={columnIcon} alt="" />
//             <p>
//               <span>{actual_data && actual_data.length} </span> Columns
//             </p>
//           </div>
//           <div className="tab-content-two">
//             <div className="tab-content-two-left-wrapper">
//               <div className="tab-content-two-left">
//                 <p>Columns</p>
//                 <p>Logical Type</p>
//               </div>
//               {loading ? (
//                 <div className="loading"> Loading ... </div>
//               ) : (
//                 actual_data &&
//                 actual_data?.map((value, index) => (
//                   <div key={index} className="left-table-data">
//                     <div className="left-table-row">
//                       <div>{value.column_name}</div>
//                       <div>{value.logical_type}</div>
//                     </div>
//                   </div>
//                 ))
//               )}
//             </div>
//             <div className="tab-content-two-right-wrapper">
//               <div className="tab-content-two-right-heading">
//                 <p>Ai Recommendations</p>
//                 <img src={startIcon} alt="icon" />
//               </div>
//               <div className="tab-content-two-right-inner-table">
//                 <div className="tab-content-two-right">
//                   <p>columns</p>
//                   <p>Description</p>
//                   <p>Sample Data</p>
//                 </div>
//                 {loading ? (
//                   <div className="loading"> Loading ... </div>
//                 ) : (
//                   sugg &&
//                   sugg.map((value, index) => (
//                     <div key={index} className="right-table-data">
//                       <div className="right-table-row">
//                         <div>{value.business_col_name}</div>
//                         <div>{value.desc}</div>
//                         <div>{value.sample_data}</div>
//                       </div>
//                     </div>
//                   ))
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="switch-tab-two-others">
//             <div className="tab-two-others-heading">Others</div>
//             <div className="tab-two-other-content">
//               <div>Unwanted Columns : </div>
//               {/* {colJustification.other[0] ? (
//                 <>{colJustification.other[0].unwanted_columns}</>
//               ) : (
//                 <>{colJustification.other.unwanted_columns[0]}</>
//               )} */}
//               <div>None</div>
//             </div>
//             <div className="tab-two-other-content">
//               <div>Columns Justification : </div>
//               {colJustification.other[0] ? (
//                 <>{colJustification.other[0].columns_justifications}</>
//               ) : (
//                 <>
//                   {Object.keys(
//                     colJustification.other.columns_justifications
//                   ).map((key, index) => (
//                     <div key={index}>
//                       {colJustification.other.columns_justifications[key]}
//                     </div>
//                   ))}
//                 </>
//               )}
//               {/* <div>{colJustification?.columns_justifications}</div> */}
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="switch-tab-loading-bar">
//           <LoadingBar />
//         </div>
//       )}
//     </div>
//   );
// }
