// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-set-flow-container {
  padding: 40px 20px;
  display: flex;
  gap: 20px;
}

.data-set-flow-right-wrapper {
  padding: 20px;
  width: 100%;
  min-height: calc(100vh - 60px);
  border-radius: 20px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
}

.back-btn {
  display: flex;
  gap: 5px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
}

.data-set-flow-heading {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.ask-ai-btn {
  cursor: pointer;
  height: 34px;
  gap: 5px;
  display: flex;
  background-color: #000000;
  padding: 8px 20px;
  border-radius: 20px;
}
.ask-ai-btn p {
  color: #ffffff;
}
/* - switch-tab -*/

/* .switch-tab-btn-wrapper {
  display: flex;
  gap: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}
.switch-tab-btn {
  font-size: 14px;
  font-weight: 400;
  color: #6e6e6e;
} */
`, "",{"version":3,"sources":["webpack://./src/components/DataSetFlow/DataSetFlow.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,oBAAoB;EACpB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,QAAQ;EACR,aAAa;EACb,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA,kBAAkB;;AAElB;;;;;;;;;;GAUG","sourcesContent":[".data-set-flow-container {\n  padding: 40px 20px;\n  display: flex;\n  gap: 20px;\n}\n\n.data-set-flow-right-wrapper {\n  padding: 20px;\n  width: 100%;\n  min-height: calc(100vh - 60px);\n  border-radius: 20px;\n  border: 1px solid #e7e7e7;\n  background-color: #ffffff;\n}\n\n.back-btn {\n  display: flex;\n  gap: 5px;\n  padding-bottom: 15px;\n  margin-bottom: 20px;\n  border-bottom: 1px solid #e7e7e7;\n}\n\n.data-set-flow-heading {\n  display: flex;\n  gap: 20px;\n  margin-bottom: 40px;\n}\n\n.ask-ai-btn {\n  cursor: pointer;\n  height: 34px;\n  gap: 5px;\n  display: flex;\n  background-color: #000000;\n  padding: 8px 20px;\n  border-radius: 20px;\n}\n.ask-ai-btn p {\n  color: #ffffff;\n}\n/* - switch-tab -*/\n\n/* .switch-tab-btn-wrapper {\n  display: flex;\n  gap: 30px;\n  padding-bottom: 10px;\n  border-bottom: 1px solid #e7e7e7;\n}\n.switch-tab-btn {\n  font-size: 14px;\n  font-weight: 400;\n  color: #6e6e6e;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
