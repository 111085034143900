import { useState } from "react";
import Tab from "./Tab";
import "./Tabs.css";
import SwitchTabOne from "./SwitchTabOne/SwitchTabOne";
import SwitchTabTwo from "./SwitchTabTwo/SwitchTabTwo";
import SwitchTabThree from "./SwitchTabThree/SwitchTabThree";
import SwitchTabFour from "./SwitchTabFour/SwitchTabFour";
import SwitchTabFive from "./SwitchTabFive/SwitchTabFive";
import SwitchTabSix from "./SwitchTabSix/SwitchTabSix";
// import axios from "axios";
// import { useEffect } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  // const [data, setData] = useState(null);
  // const [overviewdata, setOverviewdata] = useState();
  // const [businesstermsdata, setBusinesstermsdata] = useState();
  // const [rules_detected, setRule_detected] = useState();
  // const tokenStr = localStorage.getItem("accessToken");
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // const fetchData = async () => {
  //   await axios
  //     .get("http://10.0.0.166:5050/get_all_data", {
  //       headers: { Authorization: `Bearer ${tokenStr}` },
  //     })
  //     .then((response) => {
  //       setData(response.data);
  //       console.log(response.data);
  //       setOverviewdata(response.data.data_overview);
  //       // console.log(response.data.data_overview);
  //       setBusinesstermsdata(response.data.business_terms);
  //       // console.log(response.data.business_terms);
  //       setRule_detected(response.data.rules_detected);
  //       console.log(response.data.rules_detected);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div className="tabs-container">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            onClick={() => handleTabClick(index)}
            isActive={index === activeTab}
          />
        ))}
      </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <div className="tab-pane">
            <SwitchTabOne />
          </div>
        )}
        {activeTab === 1 && (
          <div className="tab-pane">
            <SwitchTabTwo />
          </div>
        )}
        {activeTab === 2 && (
          <div className="tab-pane">
            <SwitchTabThree />
          </div>
        )}
        {activeTab === 3 && (
          <div className="tab-pane">
            <SwitchTabFour />
          </div>
        )}
        {activeTab === 4 && (
          <div className="tab-pane">
            <SwitchTabFive />
          </div>
        )}
        {activeTab === 5 && (
          <div className="tab-pane">
            <SwitchTabSix />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
