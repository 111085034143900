import "./SwitchTabOne.css";
import iconOne from "./images/Icon(5).svg";
import iconTwo from "./images/Icon(1).svg";
import iconThree from "./images/Icon(2).svg";
import iconFour from "./images/Icon(3).svg";
import iconFive from "./images/Icon(4).svg";
import iconSix from "./images/Icon.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import LoadingBar from "../../LoadingBar/LoadingBar";
import { useParams } from "react-router-dom";

export default function SwitchTabOne() {
  const [data, setData] = useState(null);
  const tokenStr = localStorage.getItem("accessToken");
  const params = useParams();
  // console.log(params);
  localStorage.setItem("fileid", params.file);
  const fetchData = async () => {
    await axios
      .post(
        `https://data-quality-backend.lab.neuralcompany.team/store_data_overview/${params.file}`,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      )
      .then((response) => {
        setData(response.data);
        // console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="tab-content-one-wrapper">
      {data ? (
        <div>
          <div>
            <div className="first-section">
              <div>
                <img src={iconOne} alt="" />
                <div className="first-section-insights-wrapper">
                  <p>Total Number Tables</p>
                  <p>None</p>
                </div>
              </div>
              <div>
                <img src={iconSix} alt="" />
                <div className="first-section-insights-wrapper">
                  <p>Total Number rows</p>
                  <p>{data.data.message.rows}</p>
                </div>
              </div>
              <div>
                <img src={iconTwo} alt="" />
                <div className="first-section-insights-wrapper">
                  <p>Total Number columns</p>
                  <p>{data.data.message.columns}</p>
                </div>
              </div>
              <div>
                <img src={iconThree} alt="" />
                <div className="first-section-insights-wrapper">
                  <p>Total Number views </p>
                  <p>None</p>
                </div>
              </div>
              <div>
                <img src={iconFour} alt="" />
                <div className="first-section-insights-wrapper">
                  <p>Stored Procedures</p>
                  <p>None</p>
                </div>
              </div>
              <div>
                <img src={iconFive} alt="" />
                <div className="first-section-insights-wrapper">
                  <p>Total No of functions</p>
                  <p>None</p>
                </div>
              </div>
            </div>
            <div className="second-section">
              <div className="left-second-section">
                <div className="left-second-section-heading">
                  <p>Metadata</p>
                </div>
                <div className="left-second-section-content">
                  <p>Name</p>
                  <p>
                    {data.data.message.metadata[0]
                      ? data.data.message.metadata[0].name
                      : data.data.message.metadata.name}
                  </p>
                </div>
                <div className="left-second-section-content">
                  <p>Created at</p>
                  <p>
                    {data.data.message.metadata[0]
                      ? data.data.message.metadata[0].created_at
                      : data.data.message.metadata.created_at}
                  </p>
                </div>
                <div className="left-second-section-content">
                  <p>Relates to</p>
                  <p>
                    {data.data.message.metadata[0]
                      ? data.data.message.metadata[0].relates_to
                      : data.data.message.metadata.relates_to}
                  </p>
                </div>
                <div className="left-second-section-content">
                  <p>Connection</p>
                  <p>
                    {data.data.message.metadata[0]
                      ? data.data.message.metadata[0].connection
                      : data.data.message.metadata.connection}
                  </p>
                </div>
              </div>
              {/* <div className="right-second-section">
                <p>SLA</p>
              </div> */}
            </div>
            <div className="third-section">
              <div>
                <h4>Summary</h4>
                <pre>{data.data.message.summery}</pre>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="switch-tab-loading-bar">
          <LoadingBar />
        </div>
      )}
    </div>
  );
}
