// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#form-file-upload {
  height: 200px;
  /* width: 28rem; */
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #b23110;
  background-color: #fb78570a;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.success-message {
  font-size: 14px;
  color: green;
}
.error-message {
  font-size: 14px;
  color: red;
}
.btn-inner-wrapper{
  display: flex;
  gap: 20px;
}
.error-message{
  max-width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/SideNavBar/DataSourceForm/DataSourceForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,iCAAiC;EACjC,6BAA6B;AAC/B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,QAAQ;EACR,UAAU;EACV,WAAW;EACX,SAAS;AACX;AACA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,eAAe;EACf,UAAU;AACZ;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,gBAAgB;AAClB","sourcesContent":["#form-file-upload {\n  height: 200px;\n  /* width: 28rem; */\n  max-width: 100%;\n  text-align: center;\n  position: relative;\n}\n\n#input-file-upload {\n  display: none;\n}\n\n#label-file-upload {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-width: 1px;\n  border-radius: 1rem;\n  border-style: dashed;\n  border-color: #b23110;\n  background-color: #fb78570a;\n}\n\n#label-file-upload.drag-active {\n  background-color: #ffffff;\n}\n\n.upload-button {\n  cursor: pointer;\n  padding: 0.25rem;\n  font-size: 1rem;\n  border: none;\n  font-family: \"Oswald\", sans-serif;\n  background-color: transparent;\n}\n\n.upload-button:hover {\n  text-decoration-line: underline;\n}\n\n#drag-file-element {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  border-radius: 1rem;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n}\n.success-message {\n  font-size: 14px;\n  color: green;\n}\n.error-message {\n  font-size: 14px;\n  color: red;\n}\n.btn-inner-wrapper{\n  display: flex;\n  gap: 20px;\n}\n.error-message{\n  max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
