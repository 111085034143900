// import React, { useState } from "react";
// import "./DataSourceTable.css";
// import plusicon from "./images/plus-icon.svg";
// import deleteicon from "./images/delete-icon.svg";
// import crossicon from "./images/cross-icon.svg";
// import { useNavigate } from "react-router-dom";
// import DataSourceForm from "../../Home/SideNavBar/DataSourceForm/DataSourceForm";

// export default function DataSourceTable({ data, onRefresh, onDeleteRow }) {
//   const [isOpen, setIsOpen] = useState(false);
//   const navigate = useNavigate();

//   const getSensitivityColorClass = (sensitivity) => {
//     if (sensitivity === "high" || sensitivity === "High") {
//       return "red-text";
//     } else if (sensitivity === "low" || sensitivity === "Low") {
//       return "green-text";
//     }
//     return "";
//   };

//   function handleDataSetFlow(file_id, name) {
//     localStorage.setItem("display_name", name);
//     navigate(`/data-set-flow/${file_id}`);
//   }

//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   function dateFormat(strdate) {
//     var strSplitDate = String(strdate).split("T");
//     var add_on_date = new Date(strSplitDate[0]);
//     var dd = add_on_date.getDate();
//     var mm = add_on_date.getMonth() + 1;
//     var yy = add_on_date.getFullYear();
//     return `${dd} - ${mm} - ${yy}`;
//   }

//   return (
//     <div className="datasource-table-wrapper">
//       {/* Table header */}
//       <div className="table-header-wrapper">
//         <h4>Added Data Sources({data.length})</h4>
//         <div className="table-search-bar-wrapper">
//           <div className="add-data-source-btn" onClick={toggleSidebar}>
//             <img src={plusicon} alt="+" />
//             <p>Add Data Source</p>
//           </div>
//         </div>
//       </div>

//       {/* Table columns */}
//       <div className="columns-name">
//         <div>Name</div>
//         <div>Database Name</div>
//         <div>Data Type</div>
//         <div>Description</div>
//         <div>Added On</div>
//         <div>Updated On</div>
//         <div>Sensitivity</div>
//         <div>Action</div>
//       </div>

//       {/* Table rows */}
//       {data?.map((value, index) => (
//         <div key={index} className="table-data">
//           <div className="table-row">
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value?.file_id, value?.display_name)
//               }
//             >
//               {value?.display_name}
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value?.file_id, value?.display_name)
//               }
//             >
//               csv
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value?.file_id, value?.display_name)
//               }
//             >
//               Data Element
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value?.file_id, value?.display_name)
//               }
//             >
//               {value?.description}
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value?.file_id, value?.display_name)
//               }
//             >
//               {dateFormat(value?.upload_timestamp)}
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value?.file_id, value?.display_name)
//               }
//             >
//               {dateFormat(value?.upload_timestamp)}
//             </div>
//             <div
//               className={getSensitivityColorClass("high")}
//               onClick={() =>
//                 handleDataSetFlow(value?.file_id, value?.display_name)
//               }
//             >
//               high
//             </div>
//             <div className="column-action">
//               <img
//                 src={deleteicon}
//                 alt="deleteicon"
//                 className="delete-btn"
//                 onClick={() => onDeleteRow(value.file_id)}
//               />
//             </div>
//           </div>
//         </div>
//       ))}

//       {/* Sidebar for adding new data source */}
//       <div className={`sidebar ${isOpen ? "active" : ""}`}>
//         <div className="sd-header">
//           <h3 className="">Add Data source</h3>
//           <div className="sidebar-cross-btn" onClick={toggleSidebar}>
//             <img src={crossicon} alt="crossicon" />
//           </div>
//         </div>
//         <div className="sd-body">
//           <DataSourceForm
//             onClose={() => {
//               toggleSidebar();
//               onRefresh();
//             }}
//           />
//         </div>
//       </div>
//       <div
//         className={`sidebar-overlay ${isOpen ? "active" : ""}`}
//         onClick={toggleSidebar}
//       ></div>
//     </div>
//   );
// }

import React, { useState, useEffect, useCallback } from "react";
import "./DataSourceTable.css";
import plusicon from "./images/plus-icon.svg";
import deleteicon from "./images/delete-icon.svg";
// import editicon from "./images/edit-icon.svg";
// import searchicon from "./images/search.svg";
import crossicon from "./images/cross-icon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataSourceForm from "../../Home/SideNavBar/DataSourceForm/DataSourceForm";
// import { userContext } from "../../../authContext/UserContext";

export default function DataSourceTable({ datacsv }) {
  // const userId = useContext(userContext);
  // console.log("table", userId);
  // console.log("hello", datacsv);
  const [data, setData] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const tokenStr = localStorage.getItem("accessToken");

  const getSensitivityColorClass = (sensitivity) => {
    if (sensitivity === "high" || sensitivity === "High") {
      return "red-text";
    } else if (sensitivity === "low" || sensitivity === "Low") {
      return "green-text";
    }
    return "";
  };

  const fetchData = useCallback(() => {
    axios
      .get(
        "https://data-quality-backend.lab.neuralcompany.team/get_the_csv_data",
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      )
      .then((response) => {
        setData(response.data);

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [tokenStr]);

  useEffect(() => {
    fetchData();
  }, [fetchData, refreshTrigger]);

  function handleDataSetFlow(file_id, name) {
    localStorage.setItem("display_name", name);
    console.log("file_id", file_id);
    navigate(`/data-set-flow/${file_id}`);
  }

  function handleDeleteRow(id) {
    axios
      .delete(
        `https://data-quality-backend.lab.neuralcompany.team/delete_the_csv_data/${id}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      )
      .then((response) => {
        // console.log(response);
        setRefreshTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // function handleUpdateRow(id) {
  //   toggleSidebar();
  //   setRefreshTrigger((prev) => prev + 1);
  // }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  function dateFormat(strdate) {
    var strSplitDate = String(strdate).split("T");
    var add_on_date = new Date(strSplitDate[0]);
    var dd = add_on_date.getDate();
    var mm = add_on_date.getMonth() + 1;
    var yy = add_on_date.getFullYear();
    return `${dd} - ${mm} - ${yy}`;
  }

  return (
    <div className="datasource-table-wrapper">
      <div className="table-header-wrapper">
        <h4>Added Data Sources({data.length})</h4>
        <div className="table-search-bar-wrapper">
          {/* <div className="header-search-bar">
            <input type="text" placeholder="Search" />
            <img src={searchicon} alt="" />
          </div> */}
          <div className="add-data-source-btn" onClick={toggleSidebar}>
            <img src={plusicon} alt="+" />
            <p>Add Data Source</p>
          </div>
        </div>
      </div>
      <div className="columns-name">
        <div>Name</div>
        <div>Database Name</div>
        <div>Data Type</div>
        <div>Description</div>
        <div>Added On</div>
        <div>Updated On</div>
        <div>Sensitivity</div>
        <div>Action</div>
      </div>
      {data.map((value, index) => (
        <div key={index} className="table-data">
          <div className="table-row">
            <div
              onClick={() =>
                handleDataSetFlow(value.file_id, value.display_name)
              }
            >
              {value.display_name}
            </div>
            <div
              onClick={() =>
                handleDataSetFlow(value.file_id, value.display_name)
              }
            >
              csv
            </div>
            {/* <div>{value.database_name}</div> */}
            {/* <div>{value.data_type}</div> */}
            <div
              onClick={() =>
                handleDataSetFlow(value.file_id, value.display_name)
              }
            >
              Data Element
            </div>
            <div
              onClick={() =>
                handleDataSetFlow(value.file_id, value.display_name)
              }
            >
              {value.description}
            </div>
            <div
              onClick={() =>
                handleDataSetFlow(value.file_id, value.display_name)
              }
            >
              {dateFormat(value.upload_timestamp)}
            </div>
            <div
              onClick={() =>
                handleDataSetFlow(value.file_id, value.display_name)
              }
            >
              {dateFormat(value.upload_timestamp)}
            </div>
            {/* <div>{dateFormat(value.updated_on)}</div> */}
            <div
              className={getSensitivityColorClass("high")}
              onClick={() =>
                handleDataSetFlow(value.file_id, value.display_name)
              }
            >
              {/* {value.sensitivity} */} high
            </div>
            <div className="column-action">
              <img
                src={deleteicon}
                alt="deleteicon"
                className="delete-btn"
                onClick={() => handleDeleteRow(value.file_id)}
              />
              {/* <img
                src={editicon}
                alt="editicon"
                className="edit-btn"
                onClick={() => handleUpdateRow(value.file_id)}
              /> */}
            </div>
          </div>
        </div>
      ))}

      <div className={`sidebar ${isOpen ? "active" : ""}`}>
        <div className="sd-header">
          <h3 className="">Add Data source</h3>
          <div className="sidebar-cross-btn" onClick={toggleSidebar}>
            <img src={crossicon} alt="crossicon" />
          </div>
        </div>
        <div className="sd-body">
          <DataSourceForm onClose={toggleSidebar} />
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen ? "active" : ""}`}
        onClick={toggleSidebar}
      ></div>
    </div>
  );
}

// import React, { useState, useEffect, useCallback, useContext } from "react";
// import "./DataSourceTable.css";
// import plusicon from "./images/plus-icon.svg";
// import deleteicon from "./images/delete-icon.svg";
// import crossicon from "./images/cross-icon.svg";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import DataSourceForm from "../../Home/SideNavBar/DataSourceForm/DataSourceForm";
// import { userContext } from "../../../authContext/UserContext";

// export default function DataSourceTable({ datacsv }) {
//   const userId = useContext(userContext);
//   const [data, setData] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);

//   const navigate = useNavigate();
//   const tokenStr = localStorage.getItem("accessToken");

//   const getSensitivityColorClass = (sensitivity) => {
//     if (sensitivity === "high" || sensitivity === "High") {
//       return "red-text";
//     } else if (sensitivity === "low" || sensitivity === "Low") {
//       return "green-text";
//     }
//     return "";
//   };

//   const fetchData = useCallback(() => {
//     const storedData = localStorage.getItem("dataSourceTableData");

//     if (storedData) {
//       setData(JSON.parse(storedData));
//       // console.log("hell", data);
//     } else {
//       axios
//         .get(
//           "https://data-quality-backend.lab.neuralcompany.team/get_the_csv_data",
//           {
//             headers: { Authorization: `Bearer ${tokenStr}` },
//           }
//         )
//         .then((response) => {
//           setData(response.data);
//           // console.log("r", data);
//           localStorage.setItem(
//             "dataSourceTableData",
//             JSON.stringify(response.data)
//           );
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   }, [tokenStr]);

//   useEffect(() => {
//     const storedData = localStorage.getItem("dataSourceTableData");
//     if (storedData) {
//       setData(JSON.parse(storedData));
//       // console.log(data);
//     } else {
//       fetchData();
//     }
//   }, [fetchData]);

//   function handleDataSetFlow(file_id, name) {
//     localStorage.setItem("display_name", name);
//     navigate(`/data-set-flow/${file_id}`);
//   }

//   function handleDeleteRow(id) {
//     axios
//       .delete(
//         `https://data-quality-backend.lab.neuralcompany.team/delete_the_csv_data/${id}`,
//         {
//           headers: { Authorization: `Bearer ${tokenStr}` },
//         }
//       )
//       .then((response) => {
//         const updatedData = data.filter((item) => item.file_id !== id);
//         setData(updatedData);
//         localStorage.setItem(
//           "dataSourceTableData",
//           JSON.stringify(updatedData)
//         );
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   // function handleUpdateRow(id) {
//   //   toggleSidebar();
//   //   fetchData(); // This will update both the state and local storage
//   // }

//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   function dateFormat(strdate) {
//     var strSplitDate = String(strdate).split("T");
//     var add_on_date = new Date(strSplitDate[0]);
//     var dd = add_on_date.getDate();
//     var mm = add_on_date.getMonth() + 1;
//     var yy = add_on_date.getFullYear();
//     return `${dd} - ${mm} - ${yy}`;
//   }

//   // function clearDataSourceCache() {
//   //   localStorage.removeItem("dataSourceTableData");
//   // }

//   return (
//     <div className="datasource-table-wrapper">
//       <div className="table-header-wrapper">
//         <h4>Added Data Sources({data.length})</h4>
//         <div className="table-search-bar-wrapper">
//           <div className="add-data-source-btn" onClick={toggleSidebar}>
//             <img src={plusicon} alt="+" />
//             <p>Add Data Source</p>
//           </div>
//         </div>
//       </div>
//       <div className="columns-name">
//         <div>Name</div>
//         <div>Database Name</div>
//         <div>Data Type</div>
//         <div>Description</div>
//         <div>Added On</div>
//         <div>Updated On</div>
//         <div>Sensitivity</div>
//         <div>Action</div>
//       </div>
//       {data.map((value, index) => (
//         <div key={index} className="table-data">
//           <div className="table-row">
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value.file_id, value.display_name)
//               }
//             >
//               {value.display_name}
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value.file_id, value.display_name)
//               }
//             >
//               csv
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value.file_id, value.display_name)
//               }
//             >
//               Data Element
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value.file_id, value.display_name)
//               }
//             >
//               {value.description}
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value.file_id, value.display_name)
//               }
//             >
//               {dateFormat(value.upload_timestamp)}
//             </div>
//             <div
//               onClick={() =>
//                 handleDataSetFlow(value.file_id, value.display_name)
//               }
//             >
//               {dateFormat(value.upload_timestamp)}
//             </div>
//             <div
//               className={getSensitivityColorClass("high")}
//               onClick={() =>
//                 handleDataSetFlow(value.file_id, value.display_name)
//               }
//             >
//               high
//             </div>
//             <div className="column-action">
//               <img
//                 src={deleteicon}
//                 alt="deleteicon"
//                 className="delete-btn"
//                 onClick={() => handleDeleteRow(value.file_id)}
//               />
//             </div>
//           </div>
//         </div>
//       ))}

//       <div className={`sidebar ${isOpen ? "active" : ""}`}>
//         <div className="sd-header">
//           <h3 className="">Add Data source</h3>
//           <div className="sidebar-cross-btn" onClick={toggleSidebar}>
//             <img src={crossicon} alt="crossicon" />
//           </div>
//         </div>
//         <div className="sd-body">
//           <DataSourceForm onClose={toggleSidebar} />
//         </div>
//       </div>
//       <div
//         className={`sidebar-overlay ${isOpen ? "active" : ""}`}
//         onClick={toggleSidebar}
//       ></div>
//     </div>
//   );
// }
