// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwitchTabPartitionChecks_schema__iVUEO {
  width: 100%;
}

td {
  padding: 10px;
}

.SwitchTabPartitionChecks_schemaData__l6aY7 h4 {
  font-size: 16px;
  color: rgb(66 66 66);
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.SwitchTabPartitionChecks_schemaData__l6aY7 p {
  font-size: 16px;
  color: rgb(66 66 66);
  font-weight: 400;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataSetFlow/SwitchTabs/SwitchTabPartitionChecks/SwitchTabPartitionChecks.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".schema {\n  width: 100%;\n}\n\ntd {\n  padding: 10px;\n}\n\n.schemaData h4 {\n  font-size: 16px;\n  color: rgb(66 66 66);\n  font-weight: 400;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.schemaData p {\n  font-size: 16px;\n  color: rgb(66 66 66);\n  font-weight: 400;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schema": `SwitchTabPartitionChecks_schema__iVUEO`,
	"schemaData": `SwitchTabPartitionChecks_schemaData__l6aY7`
};
export default ___CSS_LOADER_EXPORT___;
