import "./SwitchTabThree.css";
import { useState, useEffect } from "react";
import axios from "axios";
import LoadingBar from "../../LoadingBar/LoadingBar";
import { useParams } from "react-router-dom";

export default function SwitchTabThree() {
  const [data, setData] = useState(null);
  const tokenStr = localStorage.getItem("accessToken");
  const params = useParams();
  console.log(params);
  const fetchData = () => {
    axios
      .post(
        `https://data-quality-backend.lab.neuralcompany.team/store_profiling_results/${params.file}`,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      )
      .then((response) => {
        setData(response.data.data.insights);
        // console.log(response.data);
        // console.log(response.data.profiling_results);
        // console.log(response.data.business_terms[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {data ? (
        <div>
          <div className="switch-tab-three-heading">
            <p>Profile Resulting created by AI</p>
          </div>
          <div className="switch-tab-three-wrapper">
            <div className="tab-three-table-header">
              <div></div>
              <div>
                <p>Insight Statement</p>
              </div>
              <div>
                <p>Insights Type</p>
              </div>
              <div>
                <p>Score</p>
              </div>
              <div>
                <p>Columns</p>
              </div>
            </div>
            {data?.map((row, i) => (
              <div key={i} className="tab-four-table">
                <div className="tab-three-table-row">
                  <div></div>
                  <div>{row.insight_statements}</div>
                  <div>{row.insight_type}</div>
                  <div>{row.score}</div>
                  <div>{row.col_name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="switch-tab-loading-bar">
          <LoadingBar />
        </div>
      )}
    </div>
  );
}
