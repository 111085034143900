import "./ProfilingTable.css";
import { useState, useEffect } from "react";
import React from "react";
import delete1 from "./Images/delete1.svg";
import delete2 from "./Images/delete2.svg";
import stats from "./Images/stats.svg";
import drop from "./Images/drop-down.svg";
import video from "./Images/video-run.svg";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import enable from "./Images/enable.svg";
import setting1 from "./Images/setting1.svg";
import clock from "./Images/clock.svg";
import runcheck from "./Images/run-check.svg";
import result from "./Images/result.svg";
import question from "./Images/question.svg";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { REACT_BASE_LOCAL_URL } from "../../config";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function ProfilingTable({ table, schema, connection, onBack }) {
  const [data, setData] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [activeTab, setActiveTab] = useState("data-statistics");
  const [selectedTables, setSelectedTables] = useState([]);
  const [statusData, setStatusData] = useState(null);
  const [columnsData, setColumnsData] = useState([]);
  const [tableComparisons, setTableComparisons] = useState(false);
  const [connectionLevel, setConnectionLevel] = useState("");
  const [schemaLevel, setSchemaLevel] = useState("");
  const [tableLevel, setTableLevel] = useState("");
  const [severityLevel, setSeverityLevel] = useState();
  const [advancedSection, setAdvancedSection] = useState(false);


  const fetchColumnStatistics = () => {
    axios
      .get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/statistics`
      )
      .then((response) => {
        // console.log("API Response:", response.data);
        const formattedData = response.data.column_statistics.map((column) => {
          const stats = column.statistics || [];
          const nullsPercentStat = stats.find(
            (stat) => stat.collector === "nulls_percent"
          );
          const nullsPercent = nullsPercentStat
            ? parseFloat(nullsPercentStat.result).toFixed(2) + "%"
            : "N/A";

          return {
            columnName: column.column_name || "N/A",
            detectedDataType:
              stats.find((stat) => stat.collector === "column_samples")
                ?.resultDataType || "N/A",
            importedDataType: column.type_snapshot?.column_type || "N/A",
            length: column.type_snapshot?.length || "N/A",
            nullsPercent: nullsPercent,
            distinctCount:
              stats.find((stat) => stat.collector === "distinct_count")
                ?.result || "N/A",
          };
        });
        setData(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching the data:", error);
      });
  };

  useEffect(() => {
    fetchColumnStatistics();
  }, [connection, schema, table]);

  const handleCollectStats = (columnName) => {
    const payload = {
      connection: connection,
      fullTableName: `${schema}.${table}`,
      enabled: true,
      columnNames: [columnName],
    };
    axios
      .post(
        `${REACT_BASE_LOCAL_URL}/api/jobs/collectstatistics/table?configureTable=false&wait=false`,
        payload
      )
      .then((response) => {
        axios
          .get(
            `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/statistics`
          )
          .then((response) => {
            const formattedData = response.data.column_statistics.map(
              (column) => {
                const stats = column.statistics || [];
                const nullsPercentStat = stats.find(
                  (stat) => stat.collector === "nulls_percent"
                );
                const nullsPercent = nullsPercentStat
                  ? parseFloat(nullsPercentStat.result).toFixed(2) + "%"
                  : "N/A";

                return {
                  columnName: column.column_name || "N/A",
                  detectedDataType:
                    stats.find((stat) => stat.collector === "column_samples")
                      ?.resultDataType || "N/A",
                  importedDataType: column.type_snapshot?.column_type || "N/A",
                  length: column.type_snapshot?.length || "N/A",
                  nullsPercent: nullsPercent,
                  distinctCount:
                    stats.find((stat) => stat.collector === "distinct_count")
                      ?.result || "N/A",
                };
              }
            );
            setData(formattedData);
          })
          .catch((error) => {
            console.error("Error fetching the updated data:", error);
          });
      })
      .catch((error) => {
        console.error("Error collecting statistics:", error);
      });
  };

  const handleOpenDeleteDialog = (name) => {
    setColumnName(name);
    setOpenDeleteDialog(true);
  };
  const confirmDelete = () => {
    const deleteUrl = `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/${columnName}`;
    axios
      .delete(deleteUrl)
      .then((response) => {
        console.log("Delete successful:", response);
        setRefreshTrigger((prev) => prev + 1);
        setOpenDeleteDialog(false);
        fetchColumnStatistics();
      })
      .catch((error) => {
        console.error("Delete failed:", error);
        setOpenDeleteDialog(false);
      });
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCheckboxChange = (columnName) => {
    setSelectedTables((prevSelected) =>
      prevSelected.includes(columnName)
        ? prevSelected.filter((name) => name !== columnName)
        : [...prevSelected, columnName]
    );
  };

  const fetchStatusData = () => {
    const apiUrl = `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/status?profiling=true&monitoring=false&partitioned=false`;
    axios
      .get(apiUrl)
      .then((response) => {
        setStatusData(response.data);
        const columns = Object.keys(response.data.columns).map((key) => ({
          name: key,
          ...response.data.columns[key],
        }));
        setColumnsData(columns);
      })
      .catch((error) => {
        console.error("Error fetching status data:", error);
      });
  };

  useEffect(() => {
    fetchStatusData();
  }, [connection, schema, table]);
  const formatValue = (value) =>
    value !== undefined && value !== null ? value : "N/A";

  const [checksVisibility, setChecksVisibility] = useState({});

  const toggleChecksVisibility = (columnName) => {
    setChecksVisibility((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [isVolumeExpanded, setIsVolumeExpanded] = useState(true);
  const toggleSection = (section) => {
    if (section === "volume") {
      setIsVolumeExpanded(!isVolumeExpanded);
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [checked, setChecked] = useState(false);

  const handleChange1 = () => {
    setChecked((prev) => !prev);
  };

  const options = ["Error", "Warning", "Fatal", "Multiple error"];
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("Error");

  const [previewData, setPreviewData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/columns/statistics`
      );
      const columnsData = response.data.column_statistics.map((col) => {
        const stats = {};

        // Loop through each statistic and extract values based on collectors
        col.statistics.forEach((stat) => {
          stats[stat.collector] = stat.result; // Store the result with the collector as the key
        });

        return {
          columnName: col.column_name,
          importedDatatype: col.type_snapshot?.column_type || "N/A",
          minValue:
            stats["min_value"] !== undefined ? stats["min_value"] : "N/A",
          maxValue:
            stats["max_value"] !== undefined ? stats["max_value"] : "N/A",
          distinctCount:
            stats["distinct_count"] !== undefined
              ? stats["distinct_count"]
              : "N/A",
          distinctPercent:
            stats["distinct_percent"] !== undefined
              ? stats["distinct_percent"].toFixed(2)
              : "N/A",
          duplicateCount:
            stats["duplicate_count"] !== undefined
              ? stats["duplicate_count"]
              : "N/A",
          duplicatePercent:
            stats["duplicate_percent"] !== undefined
              ? stats["duplicate_percent"].toFixed(2)
              : "N/A",
          nullsCount:
            stats["nulls_count"] !== undefined ? stats["nulls_count"] : "N/A",
          nullsPercent:
            stats["nulls_percent"] !== undefined
              ? stats["nulls_percent"].toFixed(2)
              : "N/A",
          notNullsCount:
            stats["not_nulls_count"] !== undefined
              ? stats["not_nulls_count"]
              : "N/A",
          notNullsPercent:
            stats["not_nulls_percent"] !== undefined
              ? stats["not_nulls_percent"].toFixed(2)
              : "N/A",
          textMeanLength:
            stats["text_mean_length"] !== undefined
              ? stats["text_mean_length"].toFixed(2)
              : "N/A",
          textMaxLength:
            stats["text_max_length"] !== undefined
              ? stats["text_max_length"]
              : "N/A",
          textMinLength:
            stats["text_min_length"] !== undefined
              ? stats["text_min_length"]
              : "N/A",
          samplingResults: col.statistics.filter(
            (stat) => stat.category === "sampling"
          ), // Extract sampling results
        };
      });
      setPreviewData(columnsData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [connection, schema, table]);

  const columns = Array.from({ length: 9 }, (_, i) => `Column ${i + 1}`);

  const [checksData, setChecksData] = useState([]);
  const [checkedStates, setCheckedStates] = useState(
    Array(checksData.length).fill(false)
  );
  const [selectedValues, setSelectedValues] = useState(
    Array(checksData.length).fill("None")
  );
  const [inputValues, setInputValues] = useState(
    Array(checksData.length).fill("")
  );

  const [editableValues, setEditableValues] = useState({});

  const handleEditableValueChange = (index, event) => {
    setEditableValues((prev) => ({
      ...prev,
      [index]: event.target.value,
    }));
  };

  const handleToggle = (index) => (event) => {
    setCheckedStates((prev) => ({
      ...prev,
      [index]: event.target.checked,
    }));
  };

  const handleAutocompleteChange = (index) => (event, newValue) => {
    setSelectedValues((prev) => ({
      ...prev,
      [index]: newValue,
    }));
  };

  const handleInputChange = (index) => (event) => {
    setInputValues((prev) => ({
      ...prev,
      [index]: event.target.value,
    }));
  };
  const [effectiveSchedule, setEffectiveSchedule] = useState(null);
  const [runChecksJobTemplate, setRunChecksJobTemplate] = useState(null);
  const [effectiveScheduleEnabledStatus, setEffectiveScheduleEnabledStatus] = useState('');
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).replace(',', '');
  };

  const fetchChecksData = async () => {
    try {
      const response = await axios.get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/profiling/model`
      );
      const checks = response.data.categories;
      setChecksData(checks);

      const { 
        effective_schedule, 
        run_checks_job_template, 
        effective_schedule_enabled_status 
      } = response.data;

      setEffectiveSchedule(effective_schedule);
      setRunChecksJobTemplate(run_checks_job_template);
      setEffectiveScheduleEnabledStatus(effective_schedule_enabled_status);

      const initialCheckedStates = [];
      const initialSelectedValues = [];
      const initialInputValues = [];
      const initialEditableValues = {};

      checks.forEach((category, catIndex) => {
        category.checks.forEach((check, checkIndex) => {
          const index = `${catIndex}-${checkIndex}`;
          initialCheckedStates[index] = false;
          initialSelectedValues[index] = "Error"; // Default selection
          initialInputValues[index] = "1"; // Default long_value
          initialEditableValues[index] =
            check.rule["error"].rule_parameters[0].definition.default_value; // Default value from the response
        });
      });

      setCheckedStates(initialCheckedStates);
      setSelectedValues(initialSelectedValues);
      setInputValues(initialInputValues);
      setEditableValues(initialEditableValues);
    } catch (error) {
      console.error("Error fetching checks data:", error);
    }
  };

  useEffect(() => {
    fetchChecksData();
  }, []);

  const handleSave = async () => {
    const payload = {
      categories: checksData.map((category, catIndex) => {
        return {
          category: category.category,
          checks: category.checks.map((check, checkIndex) => {
            const index = `${catIndex}-${checkIndex}`;
            const severity = selectedValues[index]?.toLowerCase();
            const updatedLongValue =
              editableValues[index] ||
              check.rule[severity]?.rule_parameters[0]?.definition
                .default_value;

            return {
              friendly_name: check.friendly_name,
              check_name: check.check_name,
              quality_dimension: check.quality_dimension,
              rule: {
                [severity]: {
                  ...check.rule[severity],
                  long_value: Number(updatedLongValue),
                },
              },
              checked: checkedStates[index] || false,
            };
          }),
        };
      }),
    };
    try {
      const response = await axios.put(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/profiling/model`,
        payload
      );
      console.log("Save response:", response.data);
    } catch (error) {
      console.error("Error saving checks data:", error);
    }
  };

  const handleRunChecks = async () => {
    const payload = {
      check_search_filters: {
        connection: connection,
        fullTableName: `${schema}.${table}`,
        enabled: true,
        checkTarget: "table",
        checkType: "profiling",
      },
    };

    try {
      const apiUrl = `${REACT_BASE_LOCAL_URL}/api/jobs/runchecks?wait=false`;
      const response = await axios.post(apiUrl, payload);
      console.log("Run checks response:", response.data);
    } catch (error) {
      console.error("Error running checks:", error);
    }
  };

  const handleRunCategoryChecks = async (category) => {
    const payload = {
      check_search_filters: {
        connection: connection,
        fullTableName: `${schema}.${table}`,
        enabled: true,
        checkTarget: "table",
        checkType: "profiling",
        checkCategory: category,
      },
      collect_error_samples: true,
    };

    try {
      const apiUrl = `${REACT_BASE_LOCAL_URL}/api/jobs/runchecks?wait=false`;
      const response = await axios.post(apiUrl, payload);
      console.log("Run checks response:", response.data);
    } catch (error) {
      console.error("Error running checks:", error);
    }
  };
  const handleIconClick = (event, category) => {
    event.stopPropagation(); // Prevent the accordion from expanding
    handleRunCategoryChecks(category); // Run the checks for the category
  };

  const [connections, setConnections] = useState([]);

  // Fetch connection names from API
  useEffect(() => {
    const fetchConnections = async () => {
      try {
        const response = await axios.get(
          `${REACT_BASE_LOCAL_URL}/api/connections`
        );
        const connectionNames = response.data.map(
          (conn) => conn.connection_name
        );
        setConnections(connectionNames); // Store connection names in state
      } catch (error) {
        console.error("Error fetching connections:", error);
      }
    };

    fetchConnections();
  }, []);

  const [schemas, setSchemas] = useState([]);

  // Fetch schemas when connectionLevel changes
  useEffect(() => {
    if (connectionLevel) {
      axios
        .get(
          `${REACT_BASE_LOCAL_URL}/api/connections/${connectionLevel}/schemas`
        )
        .then((response) => {
          const fetchedSchemas = response.data.map(
            (schema) => schema.schema_name
          );
          setSchemas(fetchedSchemas);
        })
        .catch((error) => {
          console.error("Error fetching schemas:", error);
        });
    }
  }, [connectionLevel]);

  const [tables, setTables] = useState([]);
  const [compareData, setcompareData] = useState([]);

  // Fetch tables when schemaLevel changes
  useEffect(() => {
    if (connectionLevel && schemaLevel) {
      axios
        .get(
          `${REACT_BASE_LOCAL_URL}/api/connections/${connectionLevel}/schemas/${schemaLevel}/tables`
        )
        .then((response) => {
          const fetchedTables = response.data.map(
            (table) => table.target.table_name
          );
          setTables(fetchedTables);
        })
        .catch((error) => {
          console.error("Error fetching tables:", error);
        });
    }
  }, [connectionLevel, schemaLevel]);

  const [tableComparisonName, setTableComparisonName] = useState("");
  const [showResults, setShowResults] = useState(false);
  const handleCompareSave = async () => {
    const payload = {
      table_comparison_configuration_name: tableComparisonName,
      compared_connection: connection,
      compared_table: {
        schema_name: schema,
        table_name: table,
      },
      reference_connection: connectionLevel,
      reference_table: {
        schema_name: schemaLevel,
        table_name: tableLevel,
      },
      grouping_columns: [],
    };
  
    try {
      const response = await axios.post(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/tablecomparisons/profiling`,
        payload
      );
      console.log("Success:", response.data);
      const newComparison = {
        table_comparison_configuration_name: tableComparisonName,
        compared_connection: connection,
        compared_table: {
          schema_name: schema,
          table_name: table,
        },
        reference_connection: connectionLevel,
        reference_table: {
          schema_name: schemaLevel,
          table_name: tableLevel,
        },
      };
      setcompareData((prevData) => [...prevData, newComparison]);
      setTableComparisons(false);
    } catch (error) {
      console.error("Error saving comparison:", error);
    }
  };
  

  const fetchComparisonData = async () => {
    try {
      const response = await fetch(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/tablecomparisonconfigurations?checkType=profiling`
      );
      const result = await response.json();
      setcompareData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [selectedComparison, setSelectedComparison] = useState(null);
  useEffect(() => {
    fetchComparisonData();
  }, []);
  const [comparisonResults, setComparisonResults] = useState([]); // Store API response data
  const [isLoading, setIsLoading] = useState(false);

  const handleBackToComparisons = () => {
    setShowResults(false);
    setSelectedComparison(null);
  };

  const [expandedRows, setExpandedRows] = useState({});
  const [referenceTableDetails, setReferenceTableDetails] = useState({});

  const toggleRow = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [tableExpandedRows, setTableExpandedRows] = useState([]);

  const toggleTableRow = (index) => {
    setTableExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((row) => row !== index)
        : [...prevExpandedRows, index]
    );
  };

  const handleComparisonClick = async (row) => {
    setSelectedComparison(row.table_comparison_configuration_name);
    const comparisonName = row.table_comparison_configuration_name;
    const apiUrl = `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/tablecomparisons/${comparisonName}/profiling`;

    try {
      setIsLoading(true);
      const response = await fetch(apiUrl);
      const result = await response.json();
      if (result && result.columns) {
        const mappedResults = result.columns.map((col) => ({
          compared_table_name: result.compared_table.table_name,
          reference_connection: result.reference_connection,
          reference_schema: result.reference_table.schema_name,
          reference_table: result.reference_table.table_name,
          compared_column_name: col.compared_column_name,
          reference_column_name: col.reference_column_name,
          min_error_difference_percent: col.compare_min
            ? col.compare_min.error_difference_percent
            : null,
          max_error_difference_percent: col.compare_max
            ? col.compare_max.error_difference_percent
            : null,
          sum_error_difference_percent: col.compare_sum
            ? col.compare_sum.error_difference_percent
            : null,
          mean_error_difference_percent: col.compare_mean
            ? col.compare_mean.error_difference_percent
            : null,
          null_count_error_difference_percent: col.compare_null_count
            ? col.compare_null_count.error_difference_percent
            : null,
          not_null_count_error_difference_percent: col.compare_not_null_count
            ? col.compare_not_null_count.error_difference_percent
            : null,
          default_error_difference_percent: result.default_compare_thresholds
            ? result.default_compare_thresholds.error_difference_percent
            : null,
        }));
        console.log(mappedResults, "hdhvfgvfg");
        setComparisonResults(mappedResults);
        setReferenceTableDetails({
          referenceConnection: result.reference_connection,
          referenceSchema: result.reference_table.schema_name,
          referenceTable: result.reference_table.table_name,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching comparison results:", error);
      setIsLoading(false);
    }
    setShowResults(true);
  };
  const handleBack = () => {
    setShowResults(false);
  };

  const [rowCount, setRowCount] = useState(null);
  const [columnCount, setColumnCount] = useState(null);
  const [collectedAt, setCollectedAt] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${REACT_BASE_LOCAL_URL}/api/connections/${connection}/schemas/${schema}/tables/${table}/statistics`
      )
      .then((response) => {
        const { statistics } = response.data;
        const rowCountStat = statistics.find(stat => stat.collector === "row_count");
        const columnCountStat = statistics.find(stat => stat.collector === "column_count");

        if (rowCountStat) setRowCount(rowCountStat.result);
        if (columnCountStat) setColumnCount(columnCountStat.result);
        if (rowCountStat) {
          const date = new Date(rowCountStat.collectedAt);
          const formattedDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
            date.getHours()
          ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
            date.getSeconds()
          ).padStart(2, "0")}`;
          setCollectedAt(formattedDate);
        }
      })
      .catch((error) => {
        console.error("Error fetching statistics:", error);
      });
  }, []);

  return (
    <div className="profiling-container">
      <div className="top-profiling-conatiner">
        <h1>
          Profiling checks for {connection}.{schema}.{table}
        </h1>
        <div className="profiling-action">
          <button onClick={handleSave}>Save</button>
          <button onClick={() => handleCollectStats()}>
            Collect statistics
          </button>
          <button onClick={onBack}>Back</button>
        </div>
      </div>
      <div className="tab-data-overview">
        <div
          className={`tab-data ${
            activeTab === "data-statistics" ? "active" : ""
          }`}
          onClick={() => {
            setActiveTab("data-statistics");
          }}
        >
          <p>Basic data statistics</p>
        </div>
        <div
          className={`tab-data ${
            activeTab === "table-preview" ? "active" : ""
          }`}
          onClick={() => {
            setActiveTab("table-preview");
          }}
        >
          <p>Table preview</p>
        </div>
        <div
          className={`tab-data ${
            activeTab === "table-quality-status" ? "active" : ""
          }`}
          onClick={() => {
            setActiveTab("table-quality-status");
          }}
        >
          <p>Table quality status</p>
        </div>
        <div
          className={`tab-data ${
            activeTab === "profiling-checks-editor" ? "active" : ""
          }`}
          onClick={() => {
            setActiveTab("profiling-checks-editor");
          }}
        >
          <p>Profiling checks editor</p>
        </div>
        <div
          className={`tab-data ${
            activeTab === "profiling-comparision" ? "active" : ""
          }`}
          onClick={() => {
            setActiveTab("profiling-comparision");
          }}
        >
          <p>Table comparision</p>
        </div>
        <div
          className={`tab-data ${
            activeTab === "profiling-rule" ? "active" : ""
          }`}
          onClick={() => {
            setActiveTab("profiling-rule");
          }}
        >
          <p>Data quality rule mining</p>
        </div>
      </div>
      {activeTab === "data-statistics" && (
        <div className="data-statistics-conatiner">
          <div className="table-statistics">
            <div className="table-header">
              <p>
                Total rows:{" "}
                <strong>{rowCount !== null ? rowCount : "Loading..."}</strong>
              </p>
              <p>
                Column count:{" "}
                <strong>
                  {columnCount !== null ? columnCount : "Loading..."}
                </strong>
              </p>
              <p>
                Collected at:{" "}
                <strong>
                  {collectedAt !== null ? collectedAt : "Loading..."}
                </strong>
              </p>
            </div>
            <table className="statistics-table">
              <thead>
                <tr>
                  <th>Dimensions</th>
                  <th>Column name</th>
                  <th>Detected data type</th>
                  <th>Imported data type</th>
                  <th>Length</th>
                  <th>Nulls percent</th>
                  <th>Distinct count</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <div
                          className="dimension-wrapper"
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            className="stats-input"
                            type="checkbox"
                            checked={selectedTables.includes(row.columnName)}
                            onChange={() =>
                              handleCheckboxChange(row.columnName)
                            }
                          />
                          <div className="stats-wrapper">
                            <div className="round"></div>
                            <div className="red-round"></div>
                            <div className="round"></div>
                          </div>
                        </div>
                      </td>
                      <td>{row.columnName || "N/A"}</td>
                      <td>{row.detectedDataType || "N/A"}</td>
                      <td>{row.importedDataType || "N/A"}</td>
                      <td>{row.length || "N/A"}</td>
                      <td>{row.nullsPercent || "N/A"}</td>
                      <td>
                        <div className="distinct-count">
                          {row.distinctCount || "N/A"}
                        </div>
                      </td>
                      <td>
                        <div className="profiling-action-wrapper">
                          <div className="profiling-action-buttom">
                            <img
                              src={stats}
                              alt="stats"
                              onClick={() => handleCollectStats(row.columnName)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <div className="profiling-action-buttom">
                            <img
                              onClick={() =>
                                handleOpenDeleteDialog(row.columnName)
                              }
                              src={delete1}
                              alt="delete"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            sx={{
              "& .MuiDialog-paper": { padding: "20px", borderRadius: "12px" },
            }}
          >
            <DialogTitle
              sx={{
                backgroundColor: "#f5f5f5",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              Confirm Deletion
            </DialogTitle>
            <DialogContent sx={{ padding: "16px" }}>
              <DialogContentText
                sx={{ fontSize: "1rem", color: "#666", marginBottom: "16px" }}
              >
                Are you sure you want to delete the column "{columnName}"?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "16px",
              }}
            >
              <Button
                onClick={handleCloseDeleteDialog}
                sx={{
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                  fontWeight: "bold",
                  marginRight: "8px",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  "&:hover": { backgroundColor: "#e0e0e0" },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={confirmDelete}
                sx={{
                  backgroundColor: "#d32f2f",
                  color: "white",
                  fontWeight: "bold",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  "&:hover": { backgroundColor: "#c62828" },
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}

      {activeTab === "table-preview" && (
        <div className="table-preview-conatainer">
          <table className="table-preview">
            <thead>
              <tr>
                <th>Column Name</th>
                <th>Imported Datatype</th>
                <th>Min Value</th>
                <th>Max Value</th>
                <th>Distinct Count</th>
                <th>Distinct Percent</th>
                <th>Duplicate Count</th>
                <th>Duplicate Percent</th>
                <th>Nulls Count</th>
                <th>Nulls Percent</th>
                <th>Not Nulls Count</th>
                <th>Not Nulls Percent</th>
                <th>Text Mean Length</th>
                <th>Text Max Length</th>
                <th>Text Min Length</th>
              </tr>
            </thead>
            <tbody>
              {previewData.map((row, index) => (
                <tr key={index}>
                  <td>{row.columnName || "N/A"}</td>
                  <td>{row.importedDatatype || "N/A"}</td>
                  <td>{row.minValue !== undefined ? row.minValue : "N/A"}</td>
                  <td>{row.maxValue !== undefined ? row.maxValue : "N/A"}</td>
                  <td>
                    {row.distinctCount !== undefined
                      ? row.distinctCount
                      : "N/A"}
                  </td>
                  <td>
                    {row.distinctPercent !== undefined
                      ? row.distinctPercent
                      : "N/A"}
                  </td>
                  <td>
                    {row.duplicateCount !== undefined
                      ? row.duplicateCount
                      : "N/A"}
                  </td>
                  <td>
                    {row.duplicatePercent !== undefined
                      ? row.duplicatePercent
                      : "N/A"}
                  </td>
                  <td>
                    {row.nullsCount !== undefined ? row.nullsCount : "N/A"}
                  </td>
                  <td>
                    {row.nullsPercent !== undefined ? row.nullsPercent : "N/A"}
                  </td>
                  <td>
                    {row.notNullsCount !== undefined
                      ? row.notNullsCount
                      : "N/A"}
                  </td>
                  <td>
                    {row.notNullsPercent !== undefined
                      ? row.notNullsPercent
                      : "N/A"}
                  </td>
                  <td>
                    {row.textMeanLength !== undefined
                      ? row.textMeanLength
                      : "N/A"}
                  </td>
                  <td>
                    {row.textMaxLength !== undefined
                      ? row.textMaxLength
                      : "N/A"}
                  </td>
                  <td>
                    {row.textMinLength !== undefined
                      ? row.textMinLength
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h3>Sample values</h3>
          <table className="table-preview">
            <thead>
              <tr>
                <th>Column Name</th>
                <th>Sampling Results</th>
              </tr>
            </thead>
            <tbody>
              {previewData.map((row) => {
                return row.samplingResults.length > 0 ? (
                  row.samplingResults.map((result, index) => (
                    <tr key={`${row.columnName}-${index}`}>
                      <td>{row.columnName}</td>
                      <td>{result.result}</td>
                    </tr>
                  ))
                ) : (
                  <tr key={row.columnName}>
                    <td>{row.columnName}</td>
                    <td>N/A</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {activeTab === "table-quality-status" && (
        <div className="table-quality-status">
          <div className="filters-section">
            <div className="group-checks">
              <label>Group checks by:</label>
              <input type="radio" id="category" name="group" value="category" />
              <label htmlFor="category">Category</label>
              <input
                type="radio"
                id="quality-dimension"
                name="group"
                value="quality-dimension"
                defaultChecked
              />
              <label htmlFor="quality-dimension">Quality Dimension</label>
            </div>
            <div className="date-selection-wrapper">
              <div className="date-selection">
                <input
                  type="radio"
                  id="current-month"
                  name="time"
                  value="current-month"
                />
                <label htmlFor="current-month">Current month</label>
                <input
                  type="radio"
                  id="last-3-months"
                  name="time"
                  value="last-3-months"
                />
                <label htmlFor="last-3-months">Last 3 months</label>
                <input
                  type="radio"
                  id="since"
                  name="time"
                  value="since"
                  defaultChecked
                />
                <label htmlFor="since">Since</label>
                <input type="date" id="date-start" />
              </div>
              <div className="severity-status">
                <input
                  type="radio"
                  id="current-severity"
                  name="severity"
                  value="current-severity"
                />
                <label htmlFor="current-severity">
                  Current severity status
                </label>
                <input
                  type="radio"
                  id="highest-severity"
                  name="severity"
                  value="highest-severity"
                  defaultChecked
                />
                <label htmlFor="highest-severity">
                  Highest severity status
                </label>
              </div>
            </div>
          </div>
          <div className="status-summary">
            <div className="current-status">
              <p style={{ marginBottom: "10px" }}>
                <strong>Current table status</strong>
              </p>
              <p>
                Status:{" "}
                <strong>{formatValue(statusData.current_severity)}</strong>
              </p>
              <p>
                Last check executed at:{" "}
                <strong>
                  {formatValue(
                    new Date(statusData.last_check_executed_at).toLocaleString()
                  )}
                </strong>
              </p>
              <p>
                Data quality KPI score:{" "}
                <strong>{formatValue(statusData.data_quality_kpi)}</strong>
              </p>
            </div>
            <div className="total-checks">
              <p style={{ marginBottom: "10px" }}>
                <strong>Total checks executed</strong>
              </p>
              <p>
                Total checks executed:{" "}
                <strong>{formatValue(statusData.executed_checks)}</strong>
              </p>
              <p>
                Correct results:{" "}
                <strong>{formatValue(statusData.valid_results)}</strong>
              </p>
              <p>
                Warnings: <strong>{formatValue(statusData.warnings)}</strong>
              </p>
              <p>
                Errors: <strong>{formatValue(statusData.errors)}</strong>
              </p>
              <p>
                Fatal results: <strong>{formatValue(statusData.fatals)}</strong>
              </p>
              <p>
                Execution errors:{" "}
                <strong>{formatValue(statusData.execution_errors)}</strong>
              </p>
            </div>
          </div>
          <div className="table-checks-wrapper">
            <div className="table-checks-first-row">
              <p>Validity</p>
            </div>
            <div className="table-checks-second-row">
              <p>Table level checks</p>
            </div>
            {columnsData.map((column) => (
              <div className="table-checks-third-row" key={column.name}>
                <div className="table-checks-left-coloumn">
                  <p>{formatValue(column.name)}</p>
                </div>
                <div className="table-checks-right-coloumn">
                  <div
                    className="drop-details"
                    onClick={() => toggleChecksVisibility(column.name)}
                  >
                    <img
                      src={drop}
                      alt="drop icon"
                      className={checksVisibility[column.name] ? "rotated" : ""}
                    />
                    {checksVisibility[column.name] && (
                      <div className="checks-wrapper">
                        {column.checks &&
                          Object.keys(column.checks).map((checkKey) => {
                            const check = column.checks[checkKey];
                            return (
                              <div className="checks" key={checkKey}>
                                <div className="check-key-value">
                                  <h3>Data quality checks:</h3>
                                  <p>{checkKey}</p>
                                </div>
                                <div className="check-key-value">
                                  <h3>Last executed at:</h3>
                                  <p>
                                    {formatValue(
                                      new Date(
                                        check.last_executed_at
                                      ).toLocaleString()
                                    )}
                                  </p>
                                </div>
                                <div className="check-key-value">
                                  <h3>Current severity level:</h3>
                                  <p>{formatValue(check.current_severity)}</p>
                                </div>
                                <div className="check-key-value">
                                  <h3>Highest historical severity level:</h3>
                                  <p>
                                    {formatValue(
                                      check.highest_historical_severity
                                    )}
                                  </p>
                                </div>
                                <div className="check-key-value">
                                  <h3>Category:</h3>
                                  <p>{formatValue(check.category)}</p>
                                </div>
                                <div className="check-key-value">
                                  <h3>Quality dimensions:</h3>
                                  <p>{formatValue(check.quality_dimension)}</p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === "profiling-checks-editor" && (
        <div className="profiling-checks-editor">
          <div className="top-checks-editor">
            <div className="checks-status">
              <p>Scheduling status: </p>
              <p>{runChecksJobTemplate?.enabled ? "Enabled" : "Disabled"}</p>
            </div>
            <div className="checks-status">
              <p>Scheduling configured at: </p>
              <p>{effectiveSchedule?.schedule_level}</p>
            </div>
            <div className="checks-status">
              <p>Effective cron expression: </p>
              <p>{effectiveSchedule?.cron_expression}</p>
            </div>
            <div className="checks-status">
              <p>Next execution at: </p>
              <p>
                {effectiveSchedule
                  ? formatDate(effectiveSchedule.time_of_execution)
                  : ""}
              </p>
            </div>
            <div className="checks-status">
              <p>Schedule configuration: </p>
              <p>{effectiveSchedule?.schedule_group}</p>
            </div>
          </div>
          <div className="check-editor-wrapper">
            <div className="header-all-checks">
              <p>Data quality check</p>
              <p>Issue severity level</p>
              <p>Rule thresholds</p>
              <div className="all-check-action2">
                <img src={delete2}></img>
                <img
                  src={video}
                  alt="video"
                  onClick={handleRunChecks}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div>
              {checksData.map((category, catIndex) => (
                <Accordion
                  key={catIndex}
                  expanded={expanded === `panel${catIndex + 1}`}
                  onChange={handleChange(`panel${catIndex + 1}`)}
                >
                  <AccordionSummary
                    aria-controls={`panel${catIndex + 1}-content`}
                    id={`panel${catIndex + 1}-header`}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #e7e7e7",
                      padding: "0px 22px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      <ExpandMoreIcon
                        sx={{
                          marginRight: "8px",
                          transition: "transform 0.3s",
                          transform:
                            expanded === `panel${catIndex + 1}`
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                        }}
                      />
                      <Typography style={{ fontWeight: "bold" }}>
                        {category.category}
                      </Typography>
                    </div>
                    <div
                      className="all-check-action2"
                      style={{ display: "flex", gap: "8px" }}
                    >
                      <img src={delete2} alt="delete" />
                      <img
                        src={video}
                        alt="video"
                        onClick={(event) =>
                          handleIconClick(event, category.category)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </AccordionSummary>

                  <AccordionDetails style={{ padding: "15px 22px" }}>
                    {category.checks.map((check, index) => (
                      <div
                        className="run-all-action-details"
                        key={index}
                        style={{ marginBottom: "16px" }}
                      >
                        <div className="left-action-details">
                          <div className="form-controller">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={checkedStates[index] || false}
                                  onChange={handleToggle(index)}
                                />
                              }
                            />
                          </div>
                          <img src={enable} alt="enable" />
                          <img src={setting1} alt="settings" />
                          <img src={clock} alt="clock" />
                          <img src={runcheck} alt="run check" />
                          <img src={result} alt="result" />
                          <img src={question} alt="question" />

                          <div className="row-count-details">
                            <h3>{check.friendly_name}</h3>
                            <p>{`${check.check_name} (${check.quality_dimension})`}</p>
                          </div>
                          <img src={question} alt="question" />
                        </div>

                        <div>
                          <Autocomplete
                            value={selectedValues[index] || "None"}
                            onChange={handleAutocompleteChange(index)}
                            options={options}
                            disabled={!checkedStates[index]}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Alert" />
                            )}
                          />
                        </div>
                        <Box sx={{ mt: 1 }}>
                          <Fade in={checkedStates[index] || false}>
                            <div
                              className="run-checks-data-wrapper"
                              style={{
                                backgroundColor: "#fef2e3",
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                            >
                              <div
                                className="run-check-top"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h3 style={{ margin: 0 }}>Values: </h3>
                                <img
                                  src={question}
                                  alt="question"
                                  style={{ marginLeft: "5px" }}
                                />
                              </div>
                              <div className="run-check-data">
                                <TextField
                                  value={
                                    editableValues[index] !== undefined
                                      ? editableValues[index]
                                      : check.rule[
                                          selectedValues[index]?.toLowerCase()
                                        ]?.rule_parameters[0]?.definition
                                          .default_value
                                  }
                                  onChange={(event) =>
                                    handleEditableValueChange(index, event)
                                  }
                                  disabled={!checkedStates[index]} // Disable if switch is off
                                  sx={{
                                    width: "120px",
                                    backgroundColor: "white",
                                    padding: "0",
                                  }} // Adjust width and spacing
                                  variant="outlined"
                                />
                              </div>
                            </div>
                          </Fade>
                        </Box>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      )}

      {activeTab === "profiling-comparision" && (
        <div className="TableComparionsContainer">
          {" "}
          {tableComparisons ? (
            <div className="TableComparisons">
              {" "}
              <div className="table-comparision-information">
                <div className="TableComparisonsHeading">
                  {" "}
                  <StyledTextField
                    sx={{ width: "32%" }}
                    margin="dense"
                    label="Table comparison configuration name"
                    value={tableComparisonName}
                    onChange={(e) => setTableComparisonName(e.target.value)}
                  />{" "}
                  <div className="TableComparisonsButtons">
                    {" "}
                    <button
                      onClick={() => {
                        setTableComparisons(false);
                      }}
                    >
                      {" "}
                      Back{" "}
                    </button>{" "}
                    <button
                      onClick={handleCompareSave}
                      disabled={!tableComparisonName}
                    >
                      Save
                    </button>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="ReferenceTable">
                  {" "}
                  <h3 className="Filters">
                    {" "}
                    Reference table (the source of truth){" "}
                  </h3>{" "}
                  <div>
                    {" "}
                    <StyledFormControl sx={{ width: "33.3%" }}>
                      <InputLabel id="connection-level-label">
                        Connection
                      </InputLabel>
                      <StyledSelect
                        labelId="connection-level-label"
                        id="connection-level"
                        value={connectionLevel}
                        label="Connection"
                        onChange={(e) => setConnectionLevel(e.target.value)}
                      >
                        {connections.map((connection, index) => (
                          <MenuItem key={index} value={connection}>
                            {connection}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </StyledFormControl>{" "}
                    <StyledFormControl sx={{ width: "33.3%" }}>
                      <InputLabel id="schema-level-label">Schema</InputLabel>
                      <StyledSelect
                        labelId="schema-level-label"
                        id="schema-level"
                        value={schemaLevel}
                        label="schema-level"
                        onChange={(e) => setSchemaLevel(e.target.value)}
                      >
                        {schemas.length > 0 ? (
                          schemas.map((schema, index) => (
                            <MenuItem key={index} value={schema}>
                              {schema}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No schemas available</MenuItem>
                        )}
                      </StyledSelect>
                    </StyledFormControl>{" "}
                    <StyledFormControl sx={{ width: "33.3%" }}>
                      <InputLabel id="table-level-label">Table</InputLabel>
                      <StyledSelect
                        labelId="table-level-label"
                        id="table-level"
                        value={tableLevel}
                        label="table-level"
                        onChange={(e) => setTableLevel(e.target.value)}
                      >
                        {tables.length > 0 ? (
                          tables.map((table, index) => (
                            <MenuItem key={index} value={table}>
                              {table}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No tables available</MenuItem>
                        )}
                      </StyledSelect>
                    </StyledFormControl>{" "}
                  </div>{" "}
                </div>{" "}
                <Box sx={{ overflow: "hidden" }}>
                  {" "}
                  <TableContainer component={Paper}>
                    {" "}
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="data grouping table"
                    >
                      {" "}
                      <TableHead>
                        {" "}
                        <TableRow>
                          {" "}
                          <TableCell></TableCell>{" "}
                          <TableCell>
                            {" "}
                            <h5 className="dataTableHeading">
                              {" "}
                              Data grouping on compared table{" "}
                            </h5>{" "}
                          </TableCell>{" "}
                          <TableCell>
                            {" "}
                            <h5 className="dataTableHeading">
                              {" "}
                              Data grouping on reference table{" "}
                            </h5>{" "}
                          </TableCell>{" "}
                        </TableRow>{" "}
                      </TableHead>{" "}
                      <TableBody>
                        {" "}
                        {columns.map((column) => (
                          <TableRow key={column}>
                            {" "}
                            <TableCell component="th" scope="row">
                              {" "}
                              {column}{" "}
                            </TableCell>{" "}
                            <TableCell>
                              {" "}
                              <StyledFormControl fullWidth>
                                {" "}
                                <InputLabel id="select-column-label">
                                  {" "}
                                  Select column on compared table{" "}
                                </InputLabel>{" "}
                                <StyledSelect
                                  defaultValue=""
                                  labelId="select-column-label"
                                  label="Select column on compared table"
                                >
                                  {" "}
                                  {columns.map((col) => (
                                    <MenuItem key={col} value={col}>
                                      {" "}
                                      {col}{" "}
                                    </MenuItem>
                                  ))}{" "}
                                </StyledSelect>{" "}
                              </StyledFormControl>{" "}
                            </TableCell>{" "}
                            <TableCell>
                              {" "}
                              <StyledFormControl fullWidth>
                                {" "}
                                <InputLabel id="select-reference-label">
                                  {" "}
                                  Select column on reference table{" "}
                                </InputLabel>{" "}
                                <StyledSelect
                                  defaultValue=""
                                  labelId="select-reference-label"
                                  label="Select column on reference table"
                                >
                                  {" "}
                                  {columns.map((col) => (
                                    <MenuItem key={col} value={col}>
                                      {" "}
                                      {col}{" "}
                                    </MenuItem>
                                  ))}{" "}
                                </StyledSelect>{" "}
                              </StyledFormControl>{" "}
                            </TableCell>{" "}
                          </TableRow>
                        ))}{" "}
                        <TableRow>
                          {" "}
                          <TableCell component="th" scope="row">
                            {" "}
                            WHERE filters{" "}
                          </TableCell>{" "}
                          <TableCell>
                            {" "}
                            <StyledTextField
                              fullWidth
                              multiline
                              rows={4}
                              variant="outlined"
                              placeholder="WHERE filters for compared table"
                            />{" "}
                          </TableCell>{" "}
                          <TableCell>
                            {" "}
                            <StyledTextField
                              fullWidth
                              multiline
                              rows={4}
                              variant="outlined"
                              placeholder="WHERE filters for reference table"
                            />{" "}
                          </TableCell>{" "}
                        </TableRow>{" "}
                      </TableBody>{" "}
                    </Table>{" "}
                  </TableContainer>{" "}
                </Box>{" "}
              </div>
            </div>
          ) : (
            <div>
              {!showResults ? (
                <div className="test">
                  <h3>Table comparison and its results</h3>
                  <button
                    onClick={() => setTableComparisons(true)}
                    style={{ fontSize: "16px" }}
                  >
                    New table comparison configuration
                  </button>
                  <table className="statistics-table">
                    <thead>
                      <tr>
                        <th>Table comparison configuration name</th>
                        <th>Reference connection</th>
                        <th>Reference schema</th>
                        <th>Reference table name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {compareData.length > 0 ? (
                        compareData.map((row, index) => (
                          <tr
                            key={index}
                            onClick={() => handleComparisonClick(row)}
                          >
                            <td
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {row.table_comparison_configuration_name || "N/A"}
                            </td>
                            <td>{row.reference_connection || "N/A"}</td>
                            <td>
                              {row.reference_table
                                ? row.reference_table.schema_name
                                : "N/A"}
                            </td>
                            <td>
                              {row.reference_table
                                ? row.reference_table.table_name
                                : "N/A"}
                            </td>
                            <td>
                              <div className="profiling-action-wrapper">
                                <div className="profiling-action-buttom">
                                  <img
                                    src={stats}
                                    alt="stats"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                <div className="profiling-action-buttom">
                                  <img
                                    src={delete1}
                                    alt="delete"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="result2">
                  <div className="top-result-wrapper">
                    <div className="top-table-result">
                      <div className="left-top-result">
                        <p>Table comparison configuration name:</p>
                        <p>{selectedComparison || "Select a comparison"}</p>
                      </div>
                      <div className="right-top-results">
                        <button onClick={handleBack}>Back</button>
                        <button>Delete results</button>
                        <button>Compare table</button>
                        {/* <button>Save</button> */}
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <p>Comparing this table to the reference table:</p>{" "}
                      <p>{`${
                        referenceTableDetails.referenceConnection ||
                        "Reference Connection"
                      }.${
                        referenceTableDetails.referenceSchema ||
                        "Reference Schema"
                      }.${
                        referenceTableDetails.referenceTable ||
                        "Reference Table"
                      }`}</p>
                    </div>
                  </div>
                  <div className="bottom-result-wrapper">
                    <div className="table-level-compare">
                      <table className="statistics-table">
                        <thead>
                          <tr>
                            <th>Table-level comparison</th>
                            <th></th>
                            <th>Row count </th>
                            <th>Column count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {comparisonResults && comparisonResults.length > 0 ? (
                            <React.Fragment>
                              <tr>
                                <td>
                                  <button onClick={() => toggleTableRow(0)}>
                                    {tableExpandedRows.includes(0) ? "▼" : "▶"}
                                  </button>
                                  {comparisonResults[0].compared_table_name}
                                </td>
                                <td></td>
                                <td>
                                  <input type="checkbox" />
                                </td>
                                <td>
                                  <input type="checkbox" />
                                </td>
                              </tr>
                              {tableExpandedRows.includes(0) && (
                                <tr className="expanded-row">
                                  <td colSpan="2"></td>{" "}
                                  <td style={{ padding: "0" }}>
                                    <div
                                      className="result-yellow-background"
                                      style={{
                                        backgroundColor: "rgb(253 253 237)",
                                      }}
                                    >
                                      <div className="result-values">
                                        <p></p>
                                      </div>
                                      <p>%</p>
                                    </div>
                                    <div
                                      className="result-yellow-background"
                                      style={{
                                        backgroundColor: "rgb(255 239 214)",
                                      }}
                                    >
                                      <div className="result-values">
                                        <p>
                                          {
                                            comparisonResults[0]
                                              .default_error_difference_percent
                                          }
                                        </p>
                                      </div>
                                      <p>%</p>
                                    </div>
                                    <div
                                      className="result-yellow-background"
                                      style={{
                                        backgroundColor: "rgb(254 237 236)",
                                      }}
                                    >
                                      <div className="result-values">
                                        <p></p>
                                      </div>
                                      <p>%</p>
                                    </div>
                                  </td>
                                  <td style={{ padding: "0" }}>
                                    <div
                                      className="result-yellow-background"
                                      style={{
                                        backgroundColor: "rgb(253 253 237)",
                                      }}
                                    >
                                      <div className="result-values">
                                        <p></p>
                                      </div>
                                      <p>%</p>
                                    </div>
                                    <div
                                      className="result-yellow-background"
                                      style={{
                                        backgroundColor: "rgb(255 239 214)",
                                      }}
                                    >
                                      <div className="result-values">
                                        <p>
                                          {
                                            comparisonResults[0]
                                              .default_error_difference_percent
                                          }
                                        </p>
                                      </div>
                                      <p>%</p>
                                    </div>
                                    <div
                                      className="result-yellow-background"
                                      style={{
                                        backgroundColor: "rgb(254 237 236)",
                                      }}
                                    >
                                      <div className="result-values">
                                        <p></p>
                                      </div>
                                      <p>%</p>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ) : (
                            <tr>
                              <td colSpan="8">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="compared-column">
                      <table className="statistics-table">
                        <thead>
                          <tr>
                            <th>Compared column</th>
                            <th></th>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Sum</th>
                            <th>Mean</th>
                            <th>Nulls count</th>
                            <th>Not nulls count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {comparisonResults && comparisonResults.length > 0 ? (
                            comparisonResults.map((row, index) => (
                              <React.Fragment key={index}>
                                {/* Main row with clickable arrow */}
                                <tr>
                                  <td>
                                    <button onClick={() => toggleRow(index)}>
                                      {expandedRows[index] ? "▼" : "▶"}
                                    </button>
                                    {row.compared_column_name}
                                  </td>
                                  <td>
                                    <select>
                                      <option>
                                        {row.reference_column_name}
                                      </option>
                                      {/* Additional options can be added */}
                                    </select>
                                  </td>
                                  <td>
                                    <input type="checkbox" />
                                  </td>
                                  <td>
                                    <input type="checkbox" />
                                  </td>
                                  <td>
                                    <input type="checkbox" />
                                  </td>
                                  <td>
                                    <input type="checkbox" />
                                  </td>
                                  <td>
                                    <input type="checkbox" />
                                  </td>
                                  <td>
                                    <input type="checkbox" />
                                  </td>
                                </tr>

                                {/* Expanded row content with inputs properly aligned */}
                                {expandedRows[index] && (
                                  <tr className="expanded-row">
                                    <td colSpan="2"></td>{" "}
                                    {/* Empty cells for the first two columns */}
                                    <td style={{ padding: "0" }}>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(253 253 237)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(255 239 214)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p>
                                            {row.min_error_difference_percent}
                                          </p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(254 237 236)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                    </td>
                                    <td style={{ padding: "0" }}>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(253 253 237)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(255 239 214)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p>
                                            {row.max_error_difference_percent}
                                          </p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(254 237 236)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                    </td>
                                    <td style={{ padding: "0" }}>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(253 253 237)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(255 239 214)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p>
                                            {row.sum_error_difference_percent}
                                          </p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(254 237 236)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                    </td>
                                    <td style={{ padding: "0" }}>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(253 253 237)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(255 239 214)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p>
                                            {row.mean_error_difference_percent}
                                          </p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(254 237 236)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                    </td>
                                    <td style={{ padding: "0" }}>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(253 253 237)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(255 239 214)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p>
                                            {
                                              row.null_count_error_difference_percent
                                            }
                                          </p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(254 237 236)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                    </td>
                                    <td style={{ padding: "0" }}>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(253 253 237)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p></p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(255 239 214)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p>
                                            {
                                              row.not_null_count_error_difference_percent
                                            }
                                          </p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                      <div
                                        className="result-yellow-background"
                                        style={{
                                          backgroundColor: "rgb(254 237 236)",
                                        }}
                                      >
                                        <div className="result-values">
                                          <p>1</p>
                                        </div>
                                        <p>%</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="8">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}{" "}
        </div>
      )}

      {activeTab === "profiling-rule" && (
        <div className="tabContainer">
          <div className="ruleMiningHeading">
            <p>
              The number of propositions shown depends on the activated checks
              in the Profiling section.
            </p>
            <p>
              To increase the number of propositions, you can either activate
              more profiling checks manually or use the data quality rule miner
              in the Profiling section.
            </p>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3 className="Filters">Filters</h3>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <StyledTextField
                    sx={{ width: "32%" }}
                    margin="dense"
                    label="Check category"
                  />
                  <StyledTextField
                    sx={{ width: "32%" }}
                    margin="dense"
                    label="Check name"
                  />
                  <StyledTextField
                    sx={{ width: "32%" }}
                    margin="dense"
                    label="Column name"
                  />
                </div>
              </StyledBox>
            </Grid>
            <Grid item xs={3}>
              <StyledBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                  }}
                >
                  <h3 className="Filters">Error rate (% rows)</h3>
                  <StyledTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "16px" }}
                          color="inherit"
                        >
                          The desired percentage of error rows. When a profiling
                          data quality check identifies incorrect rows and their
                          number is below this desired error rate, DQOps rule
                          mining engine will configure the rule threshold make
                          the check fail.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <AdjustIcon sx={{ fontSize: "16px", color: "#a1a1aa" }} />
                  </StyledTooltip>
                </div>
                <StyledTextField fullWidth margin="dense" label="Error rate" />
              </StyledBox>
            </Grid>
            <Grid item xs={3}>
              <StyledBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  <h3 className="Filters">Default severity level</h3>
                  <StyledFormControl fullWidth>
                    <InputLabel id="severity-level-label">
                      Severity level
                    </InputLabel>
                    <StyledSelect
                      labelId="severity-level-label"
                      id="severity-level"
                      value={severityLevel}
                      label="severity-level"
                      onChange={(e) => setSeverityLevel(e.target.value)}
                    >
                      <MenuItem value="warning">Warning</MenuItem>
                      <MenuItem value="error">Error</MenuItem>
                      <MenuItem value="fatal">Fatal</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                </div>
              </StyledBox>
            </Grid>
          </Grid>

          <div className="AdvancedParametersContainer">
            <div
              className="AdvancedParameters"
              onClick={() => {
                setAdvancedSection((prevState) => !prevState);
              }}
            >
              {advancedSection ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
              <p>Advanced parameters</p>
            </div>

            {advancedSection ? (
              <div className="optionsBox">
                <Grid
                  container
                  spacing={2}
                  sx={{
                    borderBottom: "1px solid #e7e7e7",
                    paddingBottom: "0.625rem",
                  }}
                >
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Copy failed profiling checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Copy the configuration of profiling checks that
                              failed during the last execution. The preferred
                              approach is to review the profiling checks,
                              disable false-positive checks, and enable this
                              configuration to copy the reviewed checks to the
                              monitoring and partitioned checks for continuous
                              monitoring.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Copy disabled profiling checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Copy the configuration of disabled profiling
                              checks. This option is effective for monitoring or
                              partitioned checks only. By default it is
                              disabled, leaving failed or incorrectly configured
                              profiling checks only in the profiling section to
                              avoid decreasing the data quality KPI.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Copy profiling checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Copy the configuration of enabled profiling checks
                              to the monitoring or partitioned checks. This
                              option is effective for monitoring or partitioned
                              checks only. By default it is enabled, allowing to
                              migrate configured profiling checks to the
                              monitoring section to enable Data Observability of
                              these checks.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Tune quality policy checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Reconfigure the rule thresholds of data quality
                              checks that were activated using data
                              observability rule patterns (data quality
                              policies).
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: "0.625rem 0",
                  }}
                >
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Minimum row count</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the minimum row count based on the basic
                              statistics captured from the table.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Excepted columns count</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure a table schema check that ensures that
                              the count of column stays the same as the count of
                              columns detected during data profiling.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Column exists</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure a column exists check for each column to
                              report when the column is no longer present.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Timeliness checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure all types of table's timeliness checks
                              (freshness, staleness, ingestion delay). This
                              option works only when the table is correctly
                              configured to be analyzable by timeliness check,
                              and has the timestamp column selected in the
                              configuration of the table in the Data sources
                              section.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Nulls (prohibit nulls)</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure data quality checks that detect columns
                              that have some null values in columns. When the
                              percentage of null columns is below the value of
                              the 'Error rate (% rows)' field, DQOps will raise
                              a data quality issue when any null values are
                              detected.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Not nulls (require nulls)</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure data quality checks that require that
                              columns already containing null values will
                              contain some null values. This option could be
                              desirable in some rare cases.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Detected datatype in texts</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure a check that verifies all values in a
                              text column and detects a common data type. If all
                              column values matched the same data type, such as
                              integers, floats, dates or timestamps, DQOps will
                              configure a check that will monitor if any values
                              not matching that data type appear in the column.
                              This check is usable for raw tables in the landing
                              zone.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Uniqueness checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure uniqueness checks that detect columns
                              with just a few duplicate values, and columns with
                              a relatively low number of distinct values. DQOps
                              will monitor if duplicate values appear, or the
                              number of distinct values increases.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Numeric values ranges</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Validate the values in numeric columns to detect
                              if the values fall within the ranges that were
                              observed during data profiling. DQOps will try to
                              configure the 'min', 'max', 'mean' and 'median'
                              checks.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Median and percentile ranges</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the default configuration of the median
                              and percentile in range checks that validate the
                              value at a given percentile, such as a value in
                              middle of all column values. The default value of
                              this parameter is 'false' because calculating the
                              median requires running a separate query on the
                              data source, which is not advisable for data
                              observability.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Text length ranges</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the checks that analyze text statistics
                              of text columns, such as the minimum and maximum
                              length of text values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Word count in range</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the default configuration of the minimum
                              and maximum word count of text columns. DQOps
                              applies this checks when the minimum and maximum
                              row count is at least 3 words.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Percent of true/false</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the checks that analyze boolean values
                              and will configure a range percent check for the
                              less common value (true or false).
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Dates out of range</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the checks that detect invalid dates
                              that are far in the past, or far in the future.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Values in a set (dictionary)</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Proposes the configuration the categorical values
                              checks 'value_in_set_percent from the
                              'accepted_values' category. These checks will be
                              configured to ensure that the column contains only
                              sample values that were identified during data
                              profiling.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Treat rare values as invalid</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the 'value_in_set_percent' checks from
                              the 'accepted_values' category to raise data
                              quality issues for rare values. DQOps will not add
                              rare categorical values to the list of expected
                              values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Texts in top values</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the configuration the texts_in_top_values
                              check from the 'accepted_values' category. This
                              check find the most common values in a table and
                              ensures that they are the same values that were
                              identified during data profiling.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Texts parsable of data types</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Proposes the configuration the data type
                              conversion checks that verify if text values can
                              be converted to more specific data types such as
                              boolean, date, float or integer. This type of
                              checks are used to verify raw tables in the
                              landing zones.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Standard text patterns</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the default configuration for the patterns
                              check that validate the format of popular text
                              patterns, such as UUIDS, phone numbers, or emails.
                              DQOps will configure these data quality checks
                              when analyzed columns contain enough values
                              matching a standard pattern.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Detect regular expressions</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Analyze sample text values and try to find a
                              regular expression that detects valid values
                              similar to the sample values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Whitespace checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the default configuration for the
                              whitespace detection checks. Whitespace checks
                              detect common data quality issues with storing
                              text representations of null values, such as
                              'null', 'None', 'n/a' and other texts that should
                              be stored as regular NULL values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Apply PII check rules</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Applies rules to Personal Identifiable Information
                              checks (sensitive data), but only when the checks
                              were activated manually as profiling checks, or
                              through a data quality check pattern that scans
                              all columns for PII data.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className="checkBox">
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Custom checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Custom data quality checks must use DQOps built-in
                              data quality rules, such as max_percent,
                              min_percent or max_count to find invalid values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              ""
            )}

            <div className="AdvancedParametersButtons">
              <button>Purpose</button>
              <button>Apply</button>
            </div>
          </div>

          <div className="ruleMiningHeading" style={{ marginTop: "10px" }}>
            <p>
              No new data quality checks are suggested. Please configure
              additional profiling checks manually to detect other data quality
              issues and get a new rule proposal
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfilingTable;

const StyledSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    borderRadius: ".75rem",
    borderColor: "var(--clr-border)",
    "&:hover fieldset": { borderColor: "var(--clr-dark)" },
    "&.Mui-focused fieldset": { borderColor: "var(--clr-dark)" },
  },
  "& .MuiInputLabel-root": { color: "var(--clr-dark)" },
  "& .MuiInputLabel-root.Mui-focused": { color: "var(--clr-dark)" },
  "& .MuiSelect-select": { color: "var(--clr-dark)" },
  "& .MuiInputLabel-outlined.Mui-focused": { color: "var(--clr-dark)" },
  "& .MuiOutlinedInput-notchedOutline": {
    color: "var(--clr-dark)",
    borderColor: "var(--clr-border)",
  },
});
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputLabel-root": { color: "var(--clr-dark)" },
  "& .Mui-focused .MuiInputLabel-root": { color: "var(--clr-dark)" },
  "& .MuiSelect-select": { color: "var(--clr-dark)" },
  "& .MuiInputLabel-outlined.Mui-focused": { color: "var(--clr-dark)" },
  "& .MuiOutlinedInput-notchedOutline": {
    color: "var(--clr-dark)",
    borderColor: "var(--clr-border)",
  },
}));
const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderRadius: ".75rem", borderColor: "var(--clr-border)" },
    "&:hover fieldset": { borderColor: "var(--clr-dark)" },
    "&.Mui-focused fieldset": { borderColor: "var(--clr-dark)" },
  },
  "& .MuiInputLabel-root": { color: "var(--clr-dark)" },
  "& .MuiInputLabel-root.Mui-focused": { color: "var(--clr-dark)" },
  "& .MuiInputBase-input": { color: "var(--clr-dark)" },
  "& .MuiInputBase-input::placeholder": { color: "var(--clr-dark)" },
});
const StyledBox = styled(Box)(({ theme }) => ({
  padding: "0.625rem",
  height: "100%",
  border: "1px solid var(--clr-border)",
  borderRadius: "1rem",
}));
const StyledRadio = styled(Radio)({
  color: "#fb7857",
  "&.Mui-checked": { color: "#fb7857" },
});
const StyledTableCell = styled(TableCell)({
  fontWeight: "600",
  color: "#424242",
});
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: { padding: "10px", borderRadius: "12px" },
});
