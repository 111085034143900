import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import Grid from "@mui/material/Grid";
import styles from "./PartitionTable.module.css";
import { handleGetTable } from "./PartitionTable.api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdjustIcon from "@mui/icons-material/Adjust";
import { styled } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import delete2 from "../ProfilingTable/Images/delete2.svg";
import video from "../ProfilingTable/Images/video-run.svg";
import enable from "../ProfilingTable/Images/enable.svg";
import setting1 from "../ProfilingTable/Images/setting1.svg";
import clock from "../ProfilingTable/Images/clock.svg";
import runcheck from "../ProfilingTable/Images/run-check.svg";
import result from "../ProfilingTable/Images/result.svg";
import question from "../ProfilingTable/Images/question.svg";

const organizeTableLevelChecks = (tableLevelCheck) => {
  if (!tableLevelCheck || tableLevelCheck.length === 0)
    return { byCategory: {}, byDimension: {} };

  const byCategory = {
    Availability: [],
    Datatype: [],
    Nulls: [],
    Schema: [],
    Volume: [],
  };

  const byDimension = {
    Availability: [],
    Completeness: [],
    Consistency: [],
  };

  tableLevelCheck.forEach((check) => {
    const category =
      check.tableChecks_category.charAt(0).toUpperCase() +
      check.tableChecks_category.slice(1);
    if (byCategory[category]) {
      byCategory[category].push(check.tableChecks_name);
    }

    const dimension = check.tableChecks_quality_dimension;
    if (byDimension[dimension]) {
      byDimension[dimension].push(check.tableChecks_name);
    }
  });

  return { byCategory, byDimension };
};

const organizeTableData = (checksData) => {
  if (!checksData || checksData.length === 0) return [];

  const rowsByColumnName = {};
  const categories = ["Availability", "Datatype", "Nulls", "Schema", "Volume"];

  checksData.forEach((item) => {
    if (!item.check_column_name || !item.check_category) return;

    const columnName = item.check_column_name;
    const category =
      item.check_category.charAt(0).toUpperCase() +
      item.check_category.slice(1);

    if (!rowsByColumnName[columnName]) {
      rowsByColumnName[columnName] = {
        columnName: columnName,
        categories: Object.fromEntries(categories.map((cat) => [cat, []])),
      };
    }

    if (item.check_name && categories.includes(category)) {
      if (
        !rowsByColumnName[columnName].categories[category].includes(
          item.check_name
        )
      ) {
        rowsByColumnName[columnName].categories[category].push(item.check_name);
      }
    }
  });

  return Object.values(rowsByColumnName);
};

const organizeDimensionData = (checksData, columnsData) => {
  const dimensionData = {};

  columnsData.forEach((column) => {
    if (!dimensionData[column.column_name]) {
      dimensionData[column.column_name] = {
        columnName: column.column_name,
        dimensions: {},
      };
    }
    dimensionData[column.column_name].dimensions[
      column.dimensions_column_name
    ] = [];
  });

  checksData.forEach((check) => {
    if (
      dimensionData[check.column_name] &&
      dimensionData[check.column_name].dimensions[check.check_quality_dimension]
    ) {
      dimensionData[check.column_name].dimensions[
        check.check_quality_dimension
      ].push(check.check_name);
    }
  });

  return Object.values(dimensionData);
};

function PartitionTable({ tableName, onBack }) {
  const table = tableName;
  const { connectionName } = useParams();
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState("Table quality status");
  const [timeOption, setTimeOption] = useState("Current month");
  const [groupChecks, setGroupChecks] = useState("category");
  const [sinceDate, setSinceDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [severityLevel, setSeverityLevel] = useState("");
  const [advancedSection, setAdvancedSection] = useState(false);
  const [tableComparisons, setTableComparisons] = useState(false);
  const [connectionLevel, setConnectionLevel] = useState("");
  const [schemaLevel, setSchemaLevel] = useState("");
  const [tableLevel, setTableLevel] = useState("");

  const tabOptions = [
    "Table quality status",
    "Data quality checks editor",
    "Table comparisons",
    "Copy verified profiling checks",
  ];

  useEffect(() => {
    const fetchTable = async () => {
      let sinceTime;
      const currentDate = new Date();
  
      switch (timeOption) {
        case "Current month":
          sinceTime = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          ).toISOString();
          break;
        case "Last 3 months":
          sinceTime = new Date(
            currentDate.setMonth(currentDate.getMonth() - 3)
          ).toISOString();
          break;
        case "Since":
          sinceTime = sinceDate.toISOString();
          break;
        default:
          sinceTime = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          ).toISOString();
      }
  
      const data = await handleGetTable({
        DB_NAME: connectionName,
        TABLE: table,
        sinceTime: sinceTime,
      });
  
      if (data) {
        setTableData(data);
      } else {
        console.error("No data received from the API");
      }
    };
  
    fetchTable();
  }, [timeOption, sinceDate]);  

  const checksData = [];
  if (Object.keys(tableData).length > 0) {
    Object.entries(tableData?.columns).forEach(([columnName, columnInfo]) => {
      Object.entries(columnInfo.checks).forEach(([checkName, checkInfo]) => {
        checksData?.push({
          column_name: columnName,
          check_name: checkName,
          check_info: checkInfo,
          check_column_name: checkInfo.column_name,
          check_category: checkInfo.category,
          check_quality_dimension: checkInfo.quality_dimension,
        });
      });
    });
  }

  const columnsData = [];
  if (Object.keys(tableData).length > 0) {
    Object.entries(tableData?.columns).forEach(([columnName, columnInfo]) => {
      Object.entries(columnInfo.dimensions).forEach(
        ([dimensionsName, dimensionsInfo]) => {
          columnsData?.push({
            column_name: columnName,
            dimensions_name: dimensionsName,
            dimensions_info: dimensionsInfo,
            dimensions_column_name: dimensionsInfo.dimension,
          });
        }
      );
    });
  }

  const tableLevelCheck = [];
  if (Object.keys(tableData).length > 0) {
    Object.entries(tableData?.checks).forEach(([columnName, columnInfo]) => {
      tableLevelCheck?.push({
        tableChecks_name: columnName,
        tableChecks_info: columnInfo,
        tableChecks_category: columnInfo.category,
        tableChecks_quality_dimension: columnInfo.quality_dimension,
      });
    });
  }

  const tableLevelChecksData = useMemo(() => {
    return organizeTableLevelChecks(tableLevelCheck);
  }, [tableLevelCheck]);

  const organizedData = useMemo(() => {
    return organizeTableData(checksData);
  }, [checksData]);

  const dimensionData = useMemo(() => {
    return organizeDimensionData(checksData, columnsData);
  }, [checksData, columnsData]);

  const handleGroupChecksChange = (event) => {
    setGroupChecks(event.target.value);
  };

  const handleTimeOptionChange = (event) => {
    setTimeOption(event.target.value);
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [checked, setChecked] = useState(false);

  const handleChange1 = () => {
    setChecked((prev) => !prev);
  };

  const options = ["Error", "Disabled", "Warning", "Fatal", "Multiple error"];
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("Error");

  const columns = Array.from({ length: 9 }, (_, i) => `Column ${i + 1}`);

  return (
    <div className={styles.Table}>
      <div className={styles.HeadingContainer}>
        <div className={styles.Heading}>
          <TableChartIcon sx={{ fontSize: "20x" }} />
          <h1>
            Daily monitoring checks for {tableData?.connection_name}.
            {tableData?.schema_name}.{tableData?.table_name}
          </h1>
        </div>
        <div className={styles.tableButton}>
          <button onClick={onBack}>Back</button>
        </div>
      </div>
      <div className="tab-data-overview">
        {tabOptions.map((tabName) => (
          <div
            key={tabName}
            className={`tab-data ${activeTab === tabName ? "active" : ""}`}
            onClick={() => setActiveTab(tabName)}
          >
            <p>{tabName}</p>
          </div>
        ))}
      </div>

      {activeTab === "Table quality status" && (
        <div className={styles.tabContainer}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <StyledBox>
                <h2 className={styles.StatusHeading}>Current table status</h2>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Status</td>
                      <td>{tableData.current_severity}</td>
                    </tr>
                    <tr>
                      <td>Last check executed at</td>
                      <td>{tableData.last_check_executed_at}</td>
                    </tr>
                    <tr>
                      <td>Data quality KPI score</td>
                      <td>{tableData.data_quality_kpi}</td>
                    </tr>
                  </tbody>
                </table>
              </StyledBox>
            </Grid>
            <Grid item xs={4}>
              <StyledBox>
                <h2 className={styles.StatusHeading}>Total checks executed</h2>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Total checks executed</td>
                      <td>{tableData.executed_checks}</td>
                    </tr>
                    <tr>
                      <td>Correct results</td>
                      <td>{tableData.valid_results}</td>
                    </tr>
                    <tr>
                      <td>Warnings</td>
                      <td>{tableData.warnings}</td>
                    </tr>
                    <tr>
                      <td>Errors</td>
                      <td>{tableData.errors}</td>
                    </tr>
                    <tr>
                      <td>Fatal results</td>
                      <td>{tableData.fatals}</td>
                    </tr>
                    <tr>
                      <td>Execution errors</td>
                      <td>{tableData.execution_errors}</td>
                    </tr>
                  </tbody>
                </table>
              </StyledBox>
            </Grid>
            <Grid item xs={4}>
              <StyledBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <Box sx={{ marginLeft: "6px" }}>
                  <FormControl>
                    <FormLabel
                      id="radio-buttons-group-label"
                      sx={{
                        color: "#424242",
                        "&.Mui-focused": {
                          color: "#424242",
                        },
                      }}
                    >
                      Group checks by:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={groupChecks}
                      onChange={handleGroupChecksChange}
                    >
                      <FormControlLabel
                        value="category"
                        control={<StyledRadio />}
                        label="category"
                      />
                      <FormControlLabel
                        value="quality dimension"
                        control={<StyledRadio />}
                        label="quality dimension"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box sx={{ margin: "6px 0 0 6px" }}>
                  <FormControl>
                    <FormLabel
                      id="time-buttons-group-label"
                      sx={{
                        color: "#424242",
                        "&.Mui-focused": {
                          color: "#424242",
                        },
                      }}
                    >
                      Time:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="time-buttons-group-label"
                      name="time-radio-buttons-group"
                      value={timeOption}
                      onChange={handleTimeOptionChange}
                    >
                      <FormControlLabel
                        value="Current month"
                        control={<StyledRadio />}
                        label="Current month"
                      />
                      <FormControlLabel
                        value="Last 3 months"
                        control={<StyledRadio />}
                        label="Last 3 months"
                      />
                      <FormControlLabel
                        value="Since"
                        control={<StyledRadio />}
                        label="Since"
                      />
                      {timeOption === "Since" && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={sinceDate}
                            onChange={(newValue) => {
                              setSinceDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box sx={{ margin: "6px 0 0 6px" }}>
                  <FormControl>
                    <FormLabel
                      id="radio-buttons-group-label"
                      sx={{
                        color: "#424242",
                        "&.Mui-focused": {
                          color: "#424242",
                        },
                      }}
                    >
                      Status:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Current severity status"
                        control={<StyledRadio />}
                        label="Current severity status"
                      />
                      <FormControlLabel
                        value="Highest severity status"
                        control={<StyledRadio />}
                        label="Highest severity status"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </StyledBox>
            </Grid>
            <Grid item xs={12}>
              <StyledBox>
                {groupChecks === "category" && (
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Column Name</StyledTableCell>
                          <StyledTableCell align="right">
                            Availability
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Datatype
                          </StyledTableCell>
                          <StyledTableCell align="right">Nulls</StyledTableCell>
                          <StyledTableCell align="right">
                            Schema
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Volume
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell component="th" scope="row">
                            Table Level Checks
                          </StyledTableCell>
                          {[
                            "Availability",
                            "Datatype",
                            "Nulls",
                            "Schema",
                            "Volume",
                          ].map((category) => (
                            <TableCell
                              key={category}
                              align="right"
                              sx={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                              }}
                            >
                              {tableLevelChecksData.byCategory[category]?.join(
                                "\n"
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        {organizedData.length > 0 ? (
                          organizedData.map((row) => (
                            <TableRow key={row.columnName}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ textDecoration: "underline" }}
                              >
                                {row.columnName}
                              </TableCell>
                              {[
                                "Availability",
                                "Datatype",
                                "Nulls",
                                "Schema",
                                "Volume",
                              ].map((category) => (
                                <TableCell
                                  key={category}
                                  align="right"
                                  sx={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {row.categories[category].join("\n")}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} align="center">
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {groupChecks === "quality dimension" && (
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Column Name</StyledTableCell>
                          <StyledTableCell align="right">
                            Availability
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Completeness
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Consistency
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell component="th" scope="row">
                            Table Level Checks
                          </StyledTableCell>
                          {["Availability", "Completeness", "Consistency"].map(
                            (dimension) => (
                              <TableCell
                                key={dimension}
                                align="right"
                                sx={{
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                }}
                              >
                                {tableLevelChecksData.byDimension[
                                  dimension
                                ]?.join("\n")}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                        {dimensionData.length > 0 ? (
                          dimensionData.map((row) => (
                            <TableRow key={row.columnName}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ textDecoration: "underline" }}
                              >
                                {row.columnName}
                              </TableCell>
                              {[
                                "Availability",
                                "Completeness",
                                "Consistency",
                              ].map((dimension) => (
                                <TableCell
                                  key={dimension}
                                  align="right"
                                  sx={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {row.dimensions[dimension]?.join("\n") || ""}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </StyledBox>
            </Grid>
          </Grid>
        </div>
      )}

      {activeTab === "Data quality checks editor" && (
        <div className="profiling-checks-editor">
          <div className="top-checks-editor">
            <div className="checks-status">
              <p>Scheduling status: </p>
              <p>Enabled</p>
            </div>
            <div className="checks-status">
              <p>Scheduling configured at:</p>
              <p>Connection level</p>
            </div>
            <div className="checks-status">
              <p>Effective cron expression: </p>
              <p>0 8 * * *</p>
            </div>
            <div className="checks-status">
              <p>Next execution at:</p>
              <p>Oct, 05 2024 08:00</p>
            </div>
            <div className="checks-status">
              <p>Schedule configuration:</p>
              <p>Monitoring daily</p>
            </div>
          </div>
          <div className="check-editor-wrapper">
            <div className="header-all-checks">
              <p>Data quality check</p>
              <p>Issue severity level</p>
              <p>Rule thresholds</p>
              <div className="all-check-action2">
                <img src={delete2}></img>
                <img src={video}></img>
              </div>
            </div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #e7e7e7",
                  padding: "0px 22px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <ExpandMoreIcon
                    sx={{
                      marginRight: "8px",
                      transition: "transform 0.3s",
                      transform:
                        expanded === "panel1"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                  <Typography style={{ fontWeight: "bold" }}>Volume</Typography>
                </div>
                <div
                  className="all-check-action2"
                  style={{ display: "flex", gap: "8px" }}
                >
                  <img src={delete2} alt="delete" />
                  <img src={video} alt="video" />
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "15px 22px" }}>
                <Typography></Typography>
                <div className="run-all-action-details">
                  <div className="left-action-details">
                    <div className="form-controller">
                      <FormControlLabel
                        control={
                          <Switch checked={checked} onChange={handleChange1} />
                        }
                      />
                    </div>
                    <img src={enable} alt="enable" />
                    <img src={setting1} alt="settings" />
                    <img src={clock} alt="clock" />
                    <img src={runcheck} alt="run check" />
                    <img src={result} alt="result" />
                    <img src={question} alt="question" />

                    <div className="row-count-details">
                      <h3>Minimum row count (empty or too small table)</h3>
                      <p>daily_row_count (Completeness)</p>
                    </div>
                    <img src={question} alt="question" />
                  </div>

                  <div>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) =>
                        setInputValue(newInputValue)
                      }
                      id="controllable-states-demo"
                      options={options}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Alert" />
                      )}
                    />
                  </div>
                  <Box sx={{ mt: 1 }}>
                    <Fade in={checked}>
                      <div className="run-check-data">Data.alert</div>
                    </Fade>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #e7e7e7",
                  padding: "0px 22px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <ExpandMoreIcon
                    sx={{
                      marginRight: "8px",
                      transition: "transform 0.3s",
                      transform:
                        expanded === "panel2"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                  <Typography style={{ fontWeight: "bold" }}>
                    Timeliness
                  </Typography>
                </div>
                <div
                  className="all-check-action2"
                  style={{ display: "flex", gap: "8px" }}
                >
                  <img src={delete2} alt="delete" />
                  <img src={video} alt="video" />
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "15px 22px" }}>
                <Typography></Typography>
                <div className="run-all-action-details">
                  <div className="left-action-details">
                    <div className="form-controller">
                      <FormControlLabel
                        control={
                          <Switch checked={checked} onChange={handleChange1} />
                        }
                      />
                    </div>
                    <img src={enable} alt="enable" />
                    <img src={setting1} alt="settings" />
                    <img src={clock} alt="clock" />
                    <img src={runcheck} alt="run check" />
                    <img src={result} alt="result" />
                    <img src={question} alt="question" />

                    <div className="row-count-details">
                      <h3>Minimum row count (empty or too small table)</h3>
                      <p>daily_row_count (Completeness)</p>
                    </div>
                    <img src={question} alt="question" />
                  </div>

                  <div>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) =>
                        setInputValue(newInputValue)
                      }
                      id="controllable-states-demo"
                      options={options}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Alert" />
                      )}
                    />
                  </div>
                  <Box sx={{ mt: 1 }}>
                    <Fade in={checked}>
                      <div className="run-check-data">Data.alert</div>
                    </Fade>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #e7e7e7",
                  padding: "0px 22px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <ExpandMoreIcon
                    sx={{
                      marginRight: "8px",
                      transition: "transform 0.3s",
                      transform:
                        expanded === "panel3"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                  <Typography style={{ fontWeight: "bold" }}>
                    Accuracy
                  </Typography>
                </div>
                <div
                  className="all-check-action2"
                  style={{ display: "flex", gap: "8px" }}
                >
                  <img src={delete2} alt="delete" />
                  <img src={video} alt="video" />
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "15px 22px" }}>
                <Typography></Typography>
                <div className="run-all-action-details">
                  <div className="left-action-details">
                    <div className="form-controller">
                      <FormControlLabel
                        control={
                          <Switch checked={checked} onChange={handleChange1} />
                        }
                      />
                    </div>
                    <img src={enable} alt="enable" />
                    <img src={setting1} alt="settings" />
                    <img src={clock} alt="clock" />
                    <img src={runcheck} alt="run check" />
                    <img src={result} alt="result" />
                    <img src={question} alt="question" />

                    <div className="row-count-details">
                      <h3>Minimum row count (empty or too small table)</h3>
                      <p>daily_row_count (Completeness)</p>
                    </div>
                    <img src={question} alt="question" />
                  </div>

                  <div>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) =>
                        setInputValue(newInputValue)
                      }
                      id="controllable-states-demo"
                      options={options}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Alert" />
                      )}
                    />
                  </div>
                  <Box sx={{ mt: 1 }}>
                    <Fade in={checked}>
                      <div className="run-check-data">Data.alert</div>
                    </Fade>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #e7e7e7",
                  padding: "0px 22px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <ExpandMoreIcon
                    sx={{
                      marginRight: "8px",
                      transition: "transform 0.3s",
                      transform:
                        expanded === "panel4"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                  <Typography style={{ fontWeight: "bold" }}>
                    Custom SQL
                  </Typography>
                </div>
                <div
                  className="all-check-action2"
                  style={{ display: "flex", gap: "8px" }}
                >
                  <img src={delete2} alt="delete" />
                  <img src={video} alt="video" />
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "15px 22px" }}>
                <Typography></Typography>
                <div className="run-all-action-details">
                  <div className="left-action-details">
                    <div className="form-controller">
                      <FormControlLabel
                        control={
                          <Switch checked={checked} onChange={handleChange1} />
                        }
                      />
                    </div>
                    <img src={enable} alt="enable" />
                    <img src={setting1} alt="settings" />
                    <img src={clock} alt="clock" />
                    <img src={runcheck} alt="run check" />
                    <img src={result} alt="result" />
                    <img src={question} alt="question" />

                    <div className="row-count-details">
                      <h3>Minimum row count (empty or too small table)</h3>
                      <p>daily_row_count (Completeness)</p>
                    </div>
                    <img src={question} alt="question" />
                  </div>

                  <div>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) =>
                        setInputValue(newInputValue)
                      }
                      id="controllable-states-demo"
                      options={options}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Alert" />
                      )}
                    />
                  </div>
                  <Box sx={{ mt: 1 }}>
                    <Fade in={checked}>
                      <div className="run-check-data">Data.alert</div>
                    </Fade>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #e7e7e7",
                  padding: "0px 22px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <ExpandMoreIcon
                    sx={{
                      marginRight: "8px",
                      transition: "transform 0.3s",
                      transform:
                        expanded === "panel5"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                  <Typography style={{ fontWeight: "bold" }}>
                    Availability
                  </Typography>
                </div>
                <div
                  className="all-check-action2"
                  style={{ display: "flex", gap: "8px" }}
                >
                  <img src={delete2} alt="delete" />
                  <img src={video} alt="video" />
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "15px 22px" }}>
                <Typography></Typography>
                <div className="run-all-action-details">
                  <div className="left-action-details">
                    <div className="form-controller">
                      <FormControlLabel
                        control={
                          <Switch checked={checked} onChange={handleChange1} />
                        }
                      />
                    </div>
                    <img src={enable} alt="enable" />
                    <img src={setting1} alt="settings" />
                    <img src={clock} alt="clock" />
                    <img src={runcheck} alt="run check" />
                    <img src={result} alt="result" />
                    <img src={question} alt="question" />

                    <div className="row-count-details">
                      <h3>Minimum row count (empty or too small table)</h3>
                      <p>daily_row_count (Completeness)</p>
                    </div>
                    <img src={question} alt="question" />
                  </div>

                  <div>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) =>
                        setInputValue(newInputValue)
                      }
                      id="controllable-states-demo"
                      options={options}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Alert" />
                      )}
                    />
                  </div>
                  <Box sx={{ mt: 1 }}>
                    <Fade in={checked}>
                      <div className="run-check-data">Data.alert</div>
                    </Fade>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #e7e7e7",
                  padding: "0px 22px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <ExpandMoreIcon
                    sx={{
                      marginRight: "8px",
                      transition: "transform 0.3s",
                      transform:
                        expanded === "panel6"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                  <Typography style={{ fontWeight: "bold" }}>Schema</Typography>
                </div>
                <div
                  className="all-check-action2"
                  style={{ display: "flex", gap: "8px" }}
                >
                  <img src={delete2} alt="delete" />
                  <img src={video} alt="video" />
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "15px 22px" }}>
                <Typography></Typography>
                <div className="run-all-action-details">
                  <div className="left-action-details">
                    <div className="form-controller">
                      <FormControlLabel
                        control={
                          <Switch checked={checked} onChange={handleChange1} />
                        }
                      />
                    </div>
                    <img src={enable} alt="enable" />
                    <img src={setting1} alt="settings" />
                    <img src={clock} alt="clock" />
                    <img src={runcheck} alt="run check" />
                    <img src={result} alt="result" />
                    <img src={question} alt="question" />

                    <div className="row-count-details">
                      <h3>Minimum row count (empty or too small table)</h3>
                      <p>daily_row_count (Completeness)</p>
                    </div>
                    <img src={question} alt="question" />
                  </div>

                  <div>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) =>
                        setInputValue(newInputValue)
                      }
                      id="controllable-states-demo"
                      options={options}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Alert" />
                      )}
                    />
                  </div>
                  <Box sx={{ mt: 1 }}>
                    <Fade in={checked}>
                      <div className="run-check-data">Data.alert</div>
                    </Fade>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #e7e7e7",
                  padding: "0px 22px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <ExpandMoreIcon
                    sx={{
                      marginRight: "8px",
                      transition: "transform 0.3s",
                      transform:
                        expanded === "panel7"
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                    }}
                  />
                  <Typography style={{ fontWeight: "bold" }}>
                    Uniqueness
                  </Typography>
                </div>
                <div
                  className="all-check-action2"
                  style={{ display: "flex", gap: "8px" }}
                >
                  <img src={delete2} alt="delete" />
                  <img src={video} alt="video" />
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "15px 22px" }}>
                <Typography></Typography>
                <div className="run-all-action-details">
                  <div className="left-action-details">
                    <div className="form-controller">
                      <FormControlLabel
                        control={
                          <Switch checked={checked} onChange={handleChange1} />
                        }
                      />
                    </div>
                    <img src={enable} alt="enable" />
                    <img src={setting1} alt="settings" />
                    <img src={clock} alt="clock" />
                    <img src={runcheck} alt="run check" />
                    <img src={result} alt="result" />
                    <img src={question} alt="question" />

                    <div className="row-count-details">
                      <h3>Minimum row count (empty or too small table)</h3>
                      <p>daily_row_count (Completeness)</p>
                    </div>
                    <img src={question} alt="question" />
                  </div>

                  <div>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => setValue(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) =>
                        setInputValue(newInputValue)
                      }
                      id="controllable-states-demo"
                      options={options}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Alert" />
                      )}
                    />
                  </div>
                  <Box sx={{ mt: 1 }}>
                    <Fade in={checked}>
                      <div className="run-check-data">Data.alert</div>
                    </Fade>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}

      {activeTab === "Table comparisons" && (
        <div className={styles.TableComparionsContainer}>
          {tableComparisons ? (
            <div className={styles.TableComparisons}>
              <div className={styles.TableComparisonsHeading}>
                <StyledTextField
                  sx={{ width: "32%" }}
                  margin="dense"
                  label="Table comparison configuration name"
                />
                <div className={styles.TableComparisonsButtons}>
                  <button
                    onClick={() => {
                      setTableComparisons(false);
                    }}
                  >
                    Back
                  </button>
                  <button>Save</button>
                </div>
              </div>
              <div className={styles.ReferenceTable}>
                <h3 className={styles.Filters}>
                  Reference table (the source of truth)
                </h3>
                <div>
                  <StyledFormControl sx={{ width: "33.3%" }}>
                    <InputLabel id="connection-level-label">
                      Connection
                    </InputLabel>
                    <StyledSelect
                      labelId="connection-level-label"
                      id="connection-level"
                      value={connectionLevel}
                      label="connection-level"
                      onChange={(e) => setConnectionLevel(e.target.value)}
                    >
                      <MenuItem value="Option1">Option1</MenuItem>
                      <MenuItem value="Option2">Option2</MenuItem>
                      <MenuItem value="Option3">Option3</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                  <StyledFormControl sx={{ width: "33.3%" }}>
                    <InputLabel id="schema-level-label">Schema</InputLabel>
                    <StyledSelect
                      labelId="schema-level-label"
                      id="schema-level"
                      value={schemaLevel}
                      label="schema-level"
                      onChange={(e) => setSchemaLevel(e.target.value)}
                    >
                      <MenuItem value="Option1">Option1</MenuItem>
                      <MenuItem value="Option2">Option2</MenuItem>
                      <MenuItem value="Option3">Option3</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                  <StyledFormControl sx={{ width: "33.3%" }}>
                    <InputLabel id="Table-level-label">Table</InputLabel>
                    <StyledSelect
                      labelId="table-level-label"
                      id="table-level"
                      value={tableLevel}
                      label="table-level"
                      onChange={(e) => setTableLevel(e.target.value)}
                    >
                      <MenuItem value="Option1">Option1</MenuItem>
                      <MenuItem value="Option2">Option2</MenuItem>
                      <MenuItem value="Option3">Option3</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                </div>
              </div>
              <Box sx={{ overflow: "hidden" }}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="data grouping table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <h5 className={styles.dataTableHeading}>
                            Data grouping on compared table
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={styles.dataTableHeading}>
                            Data grouping on reference table
                          </h5>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {columns.map((column) => (
                        <TableRow key={column}>
                          <TableCell component="th" scope="row">
                            {column}
                          </TableCell>
                          <TableCell>
                            <StyledFormControl fullWidth>
                              <InputLabel id="select-column-label">
                                Select column on compared table
                              </InputLabel>
                              <StyledSelect
                                defaultValue=""
                                labelId="select-column-label"
                                label="Select column on compared table"
                              >
                                {columns.map((col) => (
                                  <MenuItem key={col} value={col}>
                                    {col}
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            </StyledFormControl>
                          </TableCell>
                          <TableCell>
                            <StyledFormControl fullWidth>
                              <InputLabel id="select-reference-label">
                                Select column on reference table
                              </InputLabel>
                              <StyledSelect
                                defaultValue=""
                                labelId="select-reference-label"
                                label="Select column on reference table"
                              >
                                {columns.map((col) => (
                                  <MenuItem key={col} value={col}>
                                    {col}
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            </StyledFormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          WHERE filters
                        </TableCell>
                        <TableCell>
                          <StyledTextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            placeholder="WHERE filters for compared table"
                          />
                        </TableCell>
                        <TableCell>
                          <StyledTextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            placeholder="WHERE filters for reference table"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
          ) : (
            <button
              onClick={() => {
                setTableComparisons(true);
              }}
              style={{ fontSize: "16px" }}
            >
              New table comparsion configuration
            </button>
          )}
        </div>
      )}

      {activeTab === "Copy verified profiling checks" && (
        <div className={styles.tabContainer}>
          <div className={styles.ruleMiningHeading}>
            <p>
              The number of propositions shown depends on the activated checks
              in the Profiling section.
            </p>
            <p>
              To increase the number of propositions, you can either activate
              more profiling checks manually or use the data quality rule miner
              in the Profiling section.
            </p>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3 className={styles.Filters}>Filters</h3>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <StyledTextField
                    sx={{ width: "32%" }}
                    margin="dense"
                    label="Check category"
                  />
                  <StyledTextField
                    sx={{ width: "32%" }}
                    margin="dense"
                    label="Check name"
                  />
                  <StyledTextField
                    sx={{ width: "32%" }}
                    margin="dense"
                    label="Column name"
                  />
                </div>
              </StyledBox>
            </Grid>
            <Grid item xs={3}>
              <StyledBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                  }}
                >
                  <h3 className={styles.Filters}>Error rate (% rows)</h3>
                  <StyledTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "16px" }}
                          color="inherit"
                        >
                          The desired percentage of error rows. When a profiling
                          data quality check identifies incorrect rows and their
                          number is below this desired error rate, DQOps rule
                          mining engine will configure the rule threshold make
                          the check fail.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <AdjustIcon sx={{ fontSize: "16px", color: "#a1a1aa" }} />
                  </StyledTooltip>
                </div>
                <StyledTextField fullWidth margin="dense" label="Error rate" />
              </StyledBox>
            </Grid>
            <Grid item xs={3}>
              <StyledBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  <h3 className={styles.Filters}>Default severity level</h3>
                  <StyledFormControl fullWidth>
                    <InputLabel id="severity-level-label">
                      Severity level
                    </InputLabel>
                    <StyledSelect
                      labelId="severity-level-label"
                      id="severity-level"
                      value={severityLevel}
                      label="severity-level"
                      onChange={(e) => setSeverityLevel(e.target.value)}
                    >
                      <MenuItem value="warning">Warning</MenuItem>
                      <MenuItem value="error">Error</MenuItem>
                      <MenuItem value="fatal">Fatal</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                </div>
              </StyledBox>
            </Grid>
          </Grid>

          <div className={styles.AdvancedParametersContainer}>
            <div
              className={styles.AdvancedParameters}
              onClick={() => {
                setAdvancedSection((prevState) => !prevState);
              }}
            >
              {advancedSection ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
              <p>Advanced parameters</p>
            </div>

            {advancedSection ? (
              <div className={styles.optionsBox}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    borderBottom: "1px solid #e7e7e7",
                    paddingBottom: "0.625rem",
                  }}
                >
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Copy failed profiling checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Copy the configuration of profiling checks that
                              failed during the last execution. The preferred
                              approach is to review the profiling checks,
                              disable false-positive checks, and enable this
                              configuration to copy the reviewed checks to the
                              monitoring and partitioned checks for continuous
                              monitoring.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Copy disabled profiling checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Copy the configuration of disabled profiling
                              checks. This option is effective for monitoring or
                              partitioned checks only. By default it is
                              disabled, leaving failed or incorrectly configured
                              profiling checks only in the profiling section to
                              avoid decreasing the data quality KPI.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Copy profiling checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Copy the configuration of enabled profiling checks
                              to the monitoring or partitioned checks. This
                              option is effective for monitoring or partitioned
                              checks only. By default it is enabled, allowing to
                              migrate configured profiling checks to the
                              monitoring section to enable Data Observability of
                              these checks.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Tune quality policy checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Reconfigure the rule thresholds of data quality
                              checks that were activated using data
                              observability rule patterns (data quality
                              policies).
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: "0.625rem 0",
                  }}
                >
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Minimum row count</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the minimum row count based on the basic
                              statistics captured from the table.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Excepted columns count</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure a table schema check that ensures that
                              the count of column stays the same as the count of
                              columns detected during data profiling.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Column exists</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure a column exists check for each column to
                              report when the column is no longer present.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Timeliness checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure all types of table's timeliness checks
                              (freshness, staleness, ingestion delay). This
                              option works only when the table is correctly
                              configured to be analyzable by timeliness check,
                              and has the timestamp column selected in the
                              configuration of the table in the Data sources
                              section.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Nulls (prohibit nulls)</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure data quality checks that detect columns
                              that have some null values in columns. When the
                              percentage of null columns is below the value of
                              the 'Error rate (% rows)' field, DQOps will raise
                              a data quality issue when any null values are
                              detected.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Not nulls (require nulls)</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure data quality checks that require that
                              columns already containing null values will
                              contain some null values. This option could be
                              desirable in some rare cases.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Detected datatype in texts</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure a check that verifies all values in a
                              text column and detects a common data type. If all
                              column values matched the same data type, such as
                              integers, floats, dates or timestamps, DQOps will
                              configure a check that will monitor if any values
                              not matching that data type appear in the column.
                              This check is usable for raw tables in the landing
                              zone.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Uniqueness checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure uniqueness checks that detect columns
                              with just a few duplicate values, and columns with
                              a relatively low number of distinct values. DQOps
                              will monitor if duplicate values appear, or the
                              number of distinct values increases.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Numeric values ranges</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Validate the values in numeric columns to detect
                              if the values fall within the ranges that were
                              observed during data profiling. DQOps will try to
                              configure the 'min', 'max', 'mean' and 'median'
                              checks.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Median and percentile ranges</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the default configuration of the median
                              and percentile in range checks that validate the
                              value at a given percentile, such as a value in
                              middle of all column values. The default value of
                              this parameter is 'false' because calculating the
                              median requires running a separate query on the
                              data source, which is not advisable for data
                              observability.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Text length ranges</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the checks that analyze text statistics
                              of text columns, such as the minimum and maximum
                              length of text values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Word count in range</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the default configuration of the minimum
                              and maximum word count of text columns. DQOps
                              applies this checks when the minimum and maximum
                              row count is at least 3 words.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Percent of true/false</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the checks that analyze boolean values
                              and will configure a range percent check for the
                              less common value (true or false).
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Dates out of range</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the checks that detect invalid dates
                              that are far in the past, or far in the future.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Values in a set (dictionary)</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Proposes the configuration the categorical values
                              checks 'value_in_set_percent from the
                              'accepted_values' category. These checks will be
                              configured to ensure that the column contains only
                              sample values that were identified during data
                              profiling.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Treat rare values as invalid</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Configure the 'value_in_set_percent' checks from
                              the 'accepted_values' category to raise data
                              quality issues for rare values. DQOps will not add
                              rare categorical values to the list of expected
                              values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Texts in top values</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the configuration the texts_in_top_values
                              check from the 'accepted_values' category. This
                              check find the most common values in a table and
                              ensures that they are the same values that were
                              identified during data profiling.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Texts parsable of data types</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Proposes the configuration the data type
                              conversion checks that verify if text values can
                              be converted to more specific data types such as
                              boolean, date, float or integer. This type of
                              checks are used to verify raw tables in the
                              landing zones.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Standard text patterns</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the default configuration for the patterns
                              check that validate the format of popular text
                              patterns, such as UUIDS, phone numbers, or emails.
                              DQOps will configure these data quality checks
                              when analyzed columns contain enough values
                              matching a standard pattern.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Detect regular expressions</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Analyze sample text values and try to find a
                              regular expression that detects valid values
                              similar to the sample values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Whitespace checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Propose the default configuration for the
                              whitespace detection checks. Whitespace checks
                              detect common data quality issues with storing
                              text representations of null values, such as
                              'null', 'None', 'n/a' and other texts that should
                              be stored as regular NULL values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Apply PII check rules</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Applies rules to Personal Identifiable Information
                              checks (sensitive data), but only when the checks
                              were activated manually as profiling checks, or
                              through a data quality check pattern that scans
                              all columns for PII data.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={2.4}>
                    <div className={styles.checkBox}>
                      <Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <p>Custom checks</p>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "16px" }}
                              color="inherit"
                            >
                              Custom data quality checks must use DQOps built-in
                              data quality rules, such as max_percent,
                              min_percent or max_count to find invalid values.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <AdjustIcon
                          sx={{ fontSize: "16px", color: "#a1a1aa" }}
                        />
                      </StyledTooltip>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              ""
            )}

            <div className={styles.AdvancedParametersButtons}>
              <button>Purpose</button>
              <button>Apply</button>
            </div>
          </div>

          <div
            className={styles.ruleMiningHeading}
            style={{ marginTop: "10px" }}
          >
            <p>
              No new data quality checks are suggested. Please configure
              additional profiling checks manually to detect other data quality
              issues and get a new rule proposal
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PartitionTable;

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "0.625rem",
  height: "100%",
  border: "1px solid var(--clr-border)",
  borderRadius: "1rem",
}));

const StyledRadio = styled(Radio)({
  color: "#fb7857",
  "&.Mui-checked": {
    color: "#fb7857",
  },
});

const StyledTableCell = styled(TableCell)({
  fontWeight: "600",
  color: "#424242",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: ".75rem",
      borderColor: "var(--clr-border)",
    },
    "&:hover fieldset": { borderColor: "var(--clr-dark)" },
    "&.Mui-focused fieldset": { borderColor: "var(--clr-dark)" },
  },
  "& .MuiInputLabel-root": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputBase-input": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "var(--clr-dark)",
  },
});

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "10px",
    borderRadius: "12px",
  },
});

const StyledSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    borderRadius: ".75rem",
    borderColor: "var(--clr-border)",
    "&:hover fieldset": {
      borderColor: "var(--clr-dark)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--clr-dark)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--clr-dark)",
  },
  "& .MuiSelect-select": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "var(--clr-dark)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: "var(--clr-dark)",
    borderColor: "var(--clr-border)",
  },
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "var(--clr-dark)",
  },
  "& .Mui-focused .MuiInputLabel-root": {
    color: "var(--clr-dark)",
  },
  "& .MuiSelect-select": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "var(--clr-dark)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: "var(--clr-dark)",
    borderColor: "var(--clr-border)",
  },
}));
