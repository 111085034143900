import React, { useState, useRef } from "react";
import linkImg from "../images/link-icon.svg";
import axios from "axios";
import "./DataSourceForm.css";
import postgreSQLimg from "../images/postgreSQL.svg";
import snowflakeImg from "../images/snowflake.svg";
import amazonRedshiftImg from "../images/amazonRedshift.svg";
import microsoftAzureImg from "../images/microsoftAzure.svg";
import googleBigQueryImg from "../images/googleBigQuery.svg";
import oracleImg from "../images/oracle.svg";
import databricksImg from "../images/databricks.svg";
import teradataImg from "../images/teradata.svg";
import amazonAthinaImg from "../images/amazonAthena.svg";

export default function DataSourceForm() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [formData, setFormData] = useState({
    user_id: "",
    name: "",
    desc: "",
    sensitivity: "",
    data_type: "",
    host: "",
    port: null,
    database_name: "",
    username: "",
    password: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [isCSVUploaded, setIsCSVUploaded] = useState(false);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function refreshPage() {
    setInterval(() => {
      setSelectedFile(null);
      setIsCSVUploaded(false);
      window.location.reload(false);
    }, 5000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tokenStr = localStorage.getItem("accessToken");
    const userid = localStorage.getItem("userid");

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("desc", formData.desc);
      formDataToSend.append("user_id", userid);

      if (isCSVUploaded && selectedFile) {
        formDataToSend.append("file", selectedFile);
      } else {
        // Append other form fields if not uploading CSV
        Object.keys(formData).forEach((key) => {
          if (formData[key]) formDataToSend.append(key, formData[key]);
        });
      }

      const response = await axios.post(
        "https://data-quality-ai-backend.lab.neuralcompany.team/uploadfile",
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // .then(() => {
      //   const updatedData = data.filter(())
      // });
      setMessage(response.data.message);
      setError("");

      // const storedData = JSON.parse(
      //   localStorage.getItem("dataSourceTableData") || "[]"
      // );
      // const newData = [...storedData, response.data.newDataSource];
      // localStorage.setItem("dataSourceTableData", JSON.stringify(newData));

      // setTimeout(() => {
      //   onClose();
      // }, 2000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(
        error.response?.data?.error ||
          "An error occurred while submitting the form."
      );
      setMessage("");
    }

    // Reset form
    setFormData({
      name: "",
      desc: "",
      sensitivity: "",
      data_type: "",
      host: "",
      port: null,
      database_name: "",
      username: "",
      password: "",
    });
    refreshPage();
  };

  function handleFile(files) {
    if (files && files[0]) {
      setSelectedFile(files[0]);
      setIsCSVUploaded(true);
    }
  }

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleFileChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const clearFileSelection = () => {
    setSelectedFile(null);
    setIsCSVUploaded(false);
    setFormData((prevData) => ({
      ...prevData,
      sensitivity: "",
      data_type: "",
      host: "",
      port: null,
      database_name: "",
      username: "",
      password: "",
    }));
  };

  return (
    <>
      <div className="sd-body-heading">
        <p>Data Source type</p>
      </div>
      <div className="list-of-data-sources-wrapper">
        <div className="data-source-tool">
          <img src={postgreSQLimg} alt="postgreSQL" />
        </div>
        <div className="data-source-tool">
          <img src={microsoftAzureImg} alt="postgreSQL" />
        </div>
        <div className="data-source-tool">
          <img src={amazonRedshiftImg} alt="postgreSQL" />
        </div>
        <div className="data-source-tool">
          <img src={snowflakeImg} alt="postgreSQL" />
        </div>
        <div className="data-source-tool">
          <img src={googleBigQueryImg} alt="postgreSQL" />
        </div>
        <div className="data-source-tool">
          <img src={amazonAthinaImg} alt="postgreSQL" />
        </div>
        <div className="data-source-tool">
          <img src={teradataImg} alt="postgreSQL" />
        </div>
        <div className="data-source-tool">
          <img src={oracleImg} alt="postgreSQL" />
        </div>
        <div className="data-source-tool">
          <img src={databricksImg} alt="postgreSQL" />
        </div>
      </div>
      <div className="drag-drop-file-wrapper">
        <form
          id="form-file-upload"
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            onChange={handleFileChange}
            accept=".csv"
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
          >
            <div>
              <p>Drag and drop your CSV file here or</p>
              <button className="upload-button" onClick={onButtonClick}>
                Upload a file
              </button>
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>
      </div>
      {selectedFile && (
        <div className="selected-file-wrapper">
          <p className="selected-file">Selected file: {selectedFile.name}</p>
          <button
            onClick={clearFileSelection}
            className="data-source-connect-btn"
            style={{ marginTop: "10px" }}
          >
            <p>Clear Selection</p>
          </button>
        </div>
      )}

      <div className="data-source-form-wrapper">
        <form className="data-source-form" onSubmit={handleSubmit}>
          <div className="form-first-section">
            <label htmlFor="displayName">
              Display Name<span>*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter name to display"
              required
              value={formData.name}
              onChange={handleChange}
            />
            <label htmlFor="desc">
              Description<span>*</span>
            </label>
            <input
              type="text"
              name="desc"
              placeholder="Enter desc"
              required
              value={formData.desc}
              onChange={handleChange}
            />
            {!isCSVUploaded && (
              <>
                <label htmlFor="sensitivity">
                  Sensitivity<span></span>
                </label>
                <select
                  name="sensitivity"
                  required
                  value={formData.sensitivity}
                  onChange={handleChange}
                >
                  <option value="none">None</option>
                  <option value="high">High</option>
                  <option value="low">Low</option>
                </select>
                <label htmlFor="data_type">
                  Data Type<span></span>
                </label>
                <select
                  name="data_type"
                  required
                  value={formData.data_type}
                  onChange={handleChange}
                >
                  <option value="none">None</option>
                  <option value="data element">Data Element</option>
                  <option value="metadata">Metadata</option>
                  <option value="other">Other</option>
                </select>
              </>
            )}
          </div>

          {!isCSVUploaded && (
            <>
              <div className="form-credential-section">
                <p>Credentials</p>
                <label htmlFor="host">
                  Host<span></span>
                </label>
                <input
                  type="text"
                  name="host"
                  placeholder="Enter host name"
                  value={formData.host}
                  onChange={handleChange}
                />
                <label htmlFor="port">
                  Port<span></span>
                </label>
                <input
                  type="text"
                  name="port"
                  placeholder="Enter the port"
                  value={formData.port}
                  onChange={handleChange}
                />
                <label htmlFor="databaseName">
                  Database Name<span></span>
                </label>
                <input
                  type="text"
                  name="database_name"
                  placeholder="Enter the database name"
                  value={formData.database_name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-authentication-section">
                <p>Authentication</p>
                <label htmlFor="username">
                  User Name<span></span>
                </label>
                <input
                  type="text"
                  name="username"
                  placeholder="Enter user name"
                  value={formData.username}
                  onChange={handleChange}
                />
                <label htmlFor="password">
                  Password<span></span>
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </>
          )}

          {error && <p className="error-message">{error}</p>}
          {message && <p className="success-message">{message}</p>}
          <div className="data-source-connect-btn-wrapper">
            <div>
              <button type="submit" className="data-source-connect-btn">
                <img src={linkImg} alt="link-icon" />
                <p>Connect</p>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

// import React, { useState, useRef } from "react";
// import linkImg from "../images/link-icon.svg";
// import axios from "axios";
// import "./DataSourceForm.css";
// import postgreSQLimg from "../images/postgreSQL.svg";
// import snowflakeImg from "../images/snowflake.svg";
// import amazonRedshiftImg from "../images/amazonRedshift.svg";
// import microsoftAzureImg from "../images/microsoftAzure.svg";
// import googleBigQueryImg from "../images/googleBigQuery.svg";
// import oracleImg from "../images/oracle.svg";
// import databricksImg from "../images/databricks.svg";
// import teradataImg from "../images/teradata.svg";
// import amazonAthinaImg from "../images/amazonAthena.svg";
// // ... (other imports remain the same)

// export default function DataSourceForm() {
//   const [error, setError] = useState("");
//   const [message, setMessage] = useState("");
//   const [dragActive, setDragActive] = useState(false);
//   const [activeCSVFile, setActiveCSVFile] = useState(false);
//   // const [selectedFile, setSelectedFile] = useState(null);
//   // const inputRef = useRef(null);

//   const [formData, setFormData] = useState({
//     user_id: "",
//     name: "",
//     desc: "",
//     sensitivity: "",
//     data_type: "",
//     host: "",
//     port: null,
//     database_name: "",
//     username: "",
//     password: "",
//   });

//   const [selectedFile, setSelectedFile] = useState(null);
//   const [isCSVUploaded, setIsCSVUploaded] = useState(false);
//   const inputRef = useRef(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   function refreshPage() {
//     setInterval(() => {
//       window.location.reload(false);
//     }, 5000);
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const tokenStr = localStorage.getItem("accessToken");
//     const userid = localStorage.getItem("userid");

//     try {
//       const formDataToSend = new FormData();
//       formDataToSend.append("name", formData.name);
//       formDataToSend.append("desc", formData.desc);
//       formDataToSend.append("user_id", userid);

//       if (isCSVUploaded && selectedFile) {
//         formDataToSend.append("file", selectedFile);
//       } else {
//         // Append other form fields if not uploading CSV
//         Object.keys(formData).forEach((key) => {
//           if (formData[key]) formDataToSend.append(key, formData[key]);
//         });
//       }

//       const response = await axios.post(
//         "https://data-quality-ai-backend.lab.neuralcompany.team/uploadfile",
//         formDataToSend,
//         {
//           headers: {
//             Authorization: `Bearer ${tokenStr}`,
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       setMessage(response.data.message);
//       setError("");
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       setError(
//         error.response?.data?.error ||
//           "An error occurred while submitting the form."
//       );
//       setMessage("");
//     }

//     // Reset form
//     setFormData({
//       name: "",
//       desc: "",
//       sensitivity: "",
//       data_type: "",
//       host: "",
//       port: null,
//       database_name: "",
//       username: "",
//       password: "",
//     });
//     setSelectedFile(null);
//     setIsCSVUploaded(false);
//     refreshPage();
//   };

//   function handleFile(files) {
//     if (files && files[0]) {
//       setSelectedFile(files[0]);
//       setIsCSVUploaded(true);
//     }
//   }

//   const handleDrag = function (e) {
//     e.preventDefault();
//     e.stopPropagation();
//     if (e.type === "dragenter" || e.type === "dragover") {
//       setDragActive(true);
//     } else if (e.type === "dragleave") {
//       setDragActive(false);
//     }
//   };

//   const handleDrop = function (e) {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragActive(false);
//     if (e.dataTransfer.files && e.dataTransfer.files[0]) {
//       handleFile(e.dataTransfer.files);
//     }
//   };

//   const handleFileChange = function (e) {
//     e.preventDefault();
//     if (e.target.files && e.target.files[0]) {
//       handleFile(e.target.files);
//     }
//   };

//   const onButtonClick = () => {
//     inputRef.current.click();
//   };

//   return (
//     <>
//       <div className="sd-body-heading">
//         <p>Data Source type</p>
//       </div>
//       <div className="list-of-data-sources-wrapper">
//         <div className="data-source-tool">
//           <img src={postgreSQLimg} alt="postgreSQL" />
//         </div>
//         <div className="data-source-tool">
//           <img src={microsoftAzureImg} alt="postgreSQL" />
//         </div>
//         <div className="data-source-tool">
//           <img src={amazonRedshiftImg} alt="postgreSQL" />
//         </div>
//         <div className="data-source-tool">
//           <img src={snowflakeImg} alt="postgreSQL" />
//         </div>
//         <div className="data-source-tool">
//           <img src={googleBigQueryImg} alt="postgreSQL" />
//         </div>
//         <div className="data-source-tool">
//           <img src={amazonAthinaImg} alt="postgreSQL" />
//         </div>
//         <div className="data-source-tool">
//           <img src={teradataImg} alt="postgreSQL" />
//         </div>
//         <div className="data-source-tool">
//           <img src={oracleImg} alt="postgreSQL" />
//         </div>
//         <div className="data-source-tool">
//           <img src={databricksImg} alt="postgreSQL" />
//         </div>
//       </div>
//       <div className="drag-drop-file-wrapper">
//         <form
//           id="form-file-upload"
//           onDragEnter={handleDrag}
//           onSubmit={(e) => e.preventDefault()}
//         >
//           <input
//             ref={inputRef}
//             type="file"
//             id="input-file-upload"
//             onChange={handleFileChange}
//             accept=".csv"
//           />
//           <label
//             id="label-file-upload"
//             htmlFor="input-file-upload"
//             className={dragActive ? "drag-active" : ""}
//           >
//             <div>
//               <p>Drag and drop your CSV file here or</p>
//               <button className="upload-button" onClick={onButtonClick}>
//                 Upload a file
//               </button>
//             </div>
//           </label>
//           {dragActive && (
//             <div
//               id="drag-file-element"
//               onDragEnter={handleDrag}
//               onDragLeave={handleDrag}
//               onDragOver={handleDrag}
//               onDrop={handleDrop}
//             ></div>
//           )}
//         </form>
//       </div>
//       {selectedFile && (
//         <p className="selected-file">Selected file: {selectedFile.name}</p>
//       )}

//       <div className="data-source-form-wrapper">
//         <form className="data-source-form" onSubmit={handleSubmit}>
//           <div className="form-first-section">
//             <label htmlFor="displayName">
//               Display Name<span>*</span>
//             </label>
//             <input
//               type="text"
//               name="name"
//               placeholder="Enter name to display"
//               required
//               value={formData.name}
//               onChange={handleChange}
//             />
//             <label htmlFor="desc">
//               Description<span>*</span>
//             </label>
//             <input
//               type="text"
//               name="desc"
//               placeholder="Enter desc"
//               required
//               value={formData.desc}
//               onChange={handleChange}
//             />
//             <label htmlFor="sensitivity">
//               Sensitivity<span></span>
//             </label>
//             <select
//               name="sensitivity"
//               required
//               value={formData.sensitivity}
//               onChange={handleChange}
//               disabled={isCSVUploaded}
//             >
//               <option value="none">None</option>
//               <option value="high">High</option>
//               <option value="low">Low</option>
//             </select>
//             <label htmlFor="data_type">
//               Data Type<span></span>
//             </label>
//             <select
//               name="data_type"
//               required
//               value={formData.data_type}
//               onChange={handleChange}
//               disabled={isCSVUploaded}
//             >
//               <option value="none">None</option>
//               <option value="data element">Data Element</option>
//               <option value="metadata">Metadata</option>
//               <option value="other">Other</option>
//             </select>
//           </div>

//           <div className="form-credential-section">
//             <p>Credentials</p>
//             <label htmlFor="host">
//               Host<span></span>
//             </label>
//             <input
//               type="text"
//               name="host"
//               placeholder="Enter host name"
//               value={formData.host}
//               onChange={handleChange}
//               // disabled={activeCSVFile}
//               disabled={isCSVUploaded}
//             />
//             <label htmlFor="port">
//               Port<span></span>
//             </label>
//             <input
//               type="text"
//               name="port"
//               placeholder="Enter the port"
//               value={formData.port}
//               onChange={handleChange}
//               // disabled={activeCSVFile}
//               disabled={isCSVUploaded}
//             />
//             <label htmlFor="databaseName">
//               Database Name<span></span>
//             </label>
//             <input
//               type="text"
//               name="database_name"
//               placeholder="Enter the database name"
//               value={formData.database_name}
//               onChange={handleChange}
//               // disabled={activeCSVFile}
//               disabled={isCSVUploaded}
//             />
//           </div>
//           <div className="form-authentication-section">
//             <p>Authentication</p>
//             <label htmlFor="username">
//               User Name<span></span>
//             </label>
//             <input
//               type="text"
//               name="username"
//               placeholder="Enter user name"
//               value={formData.username}
//               onChange={handleChange}
//               // disabled={activeCSVFile}
//               disabled={isCSVUploaded}
//             />
//             <label htmlFor="password">
//               Password<span></span>
//             </label>
//             <input
//               type="password"
//               name="password"
//               placeholder="Password"
//               value={formData.password}
//               onChange={handleChange}
//               // disabled={activeCSVFile}
//               disabled={isCSVUploaded}
//             />
//           </div>

//           {error && <p className="error-message">{error}</p>}
//           {message && <p className="success-message">{message}</p>}
//           <div className="data-source-connect-btn-wrapper">
//             <div>
//               <button type="submit" className="data-source-connect-btn">
//                 <img src={linkImg} alt="link-icon" />
//                 <p>Connect</p>
//               </button>
//             </div>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// }
