// DashboardEmbed.js
import React, { useEffect } from "react";
import axios from "axios";
import { embedDashboard } from "@superset-ui/embedded-sdk";

export default function DashboardEmbed({ dashboardId }) {
  const supersetApiUrl = process.env.REACT_APP_SUPERSET_API_URL; 
  const username = process.env.REACT_APP_SUPERSET_USERNAME; 
  const password = process.env.REACT_APP_SUPERSET_PASSWORD; 
  const supersetDomain = process.env.REACT_APP_SUPERSET_DOMAIN; 
  const embeddedUsername = process.env.REACT_APP_EMBEDDED_USERNAME; 
  const firstName = process.env.REACT_APP_FIRST_NAME; 
  const lastName = process.env.REACT_APP_LAST_NAME; 

  useEffect(() => {
    console.log("Environment Variables:", {
      supersetApiUrl,
      username,
      password,
      supersetDomain,
      embeddedUsername,
      firstName,
      lastName
    });

    async function getToken() {
      try {
        const login_body = {
          username: username, 
          password: password, 
          refresh: true,
          provider: "db"
        };

        const login_headers = {
          headers: {
            "Content-Type": "application/json"
          }
        };

        const { data } = await axios.post(`${supersetApiUrl}/login`, login_body, login_headers);
        const access_token = data['access_token'];

        if (!access_token) {
          throw new Error("Failed to obtain access token");
        }

        const guest_token_body = {
          resources: [
            {
              type: "dashboard",
              id: dashboardId,
            }
          ],
          rls: [],
          user: {
            username: embeddedUsername, 
            first_name: firstName, 
            last_name: lastName, 
          }
        };

        const guest_token_headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
          }
        };

        const dt = await axios.post(`${supersetApiUrl}/guest_token/`, guest_token_body, guest_token_headers);

        embedDashboard({
          id: dashboardId,
          supersetDomain: supersetDomain, 
          mountPoint: document.getElementById("superset-container"),
          fetchGuestToken: () => dt.data['token'],
          dashboardUiConfig: { hideTitle: true }
        });

        // Adjust iframe styles
        var iframe = document.querySelector("iframe");
        if (iframe) {
          iframe.style.width = '100%'; 
          iframe.style.minHeight = '51vw'; 
        }
      } catch (error) {
        console.error("Error during API call:", error.response ? error.response.data : error.message);
      }
    }

    getToken();
  }, [supersetApiUrl, dashboardId, username, password, supersetDomain, embeddedUsername, firstName, lastName]);

  return <div id='superset-container'></div>;
}
