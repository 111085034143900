import React, { useState, useEffect, useCallback } from "react";
import "./DataSourceTable.css";
import plusicon from "./images/plus-icon.svg";
import crossicon from "./images/cross-icon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataSourceForm from "../../Home/SideNavBar/DataSourceForm/DataSourceForm";
import { Checkbox, FormControlLabel, Typography, Divider, InputAdornment } from "@mui/material";
import Box from "@mui/joy/Box";
import Badge from "@mui/joy/Badge";

import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import right from "../images/right.png";
import add from "../images/add1.svg";
import { REACT_BASE_LOCAL_URL } from "../../../config";
import {
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


export default function DataSourceTable({ datacsv }) {
  const [checkTarget, setCheckTarget] = useState(""); 
  const [checkType, setCheckType] = useState(""); 
  const [timeScale, setTimeScale] = useState("");
  const [data, setData] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedConnectionName, setSelectedConnectionName] = useState(null);
  const [openRunChecksDialog, setOpenRunChecksDialog] = useState(false);
  const [connectionName, setConnectionName] = useState("");
  const [schemaTableName, setSchemaTableName] = useState("");
  const [columnName, setColumnName] = useState("");
  const [columnDatatype, setColumnDatatype] = useState("");
  const [columnNullable, setColumnNullable] = useState("");
  const [collectErrorSamples, setCollectErrorSamples] = useState(false);
  const [additionalParameters, setAdditionalParameters] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [showDetails, setShowDetails] = useState({});

  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    axios
      .get(REACT_BASE_LOCAL_URL + "/api/connections")
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, refreshTrigger]);

  function handleDataSetFlow(connection_hash, connection_name) {
    localStorage.setItem("display_name", connection_name);
    navigate(`/data-set-flow/${connection_name}`);
  }

  const handleDeleteRow = (connectionName) => {
    setSelectedConnectionName(connectionName);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = () => {
    const deleteUrl =
      REACT_BASE_LOCAL_URL + `/api/connections/${selectedConnectionName}`;
    axios
      .delete(deleteUrl)
      .then((response) => {
        console.log("Delete successful:", response);
        setRefreshTrigger((prev) => prev + 1);
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        console.error("Delete failed:", error);
        setOpenDeleteDialog(false);
      });
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleRunChecks = () => {
    const fromDate = new Date();
    const toDate = new Date();
    toDate.setDate(fromDate.getDate() + 7);
    fromDate.setDate(fromDate.getDate() - 30);
  
    const formattedFromDate = fromDate.toISOString().split("T")[0];
    const formattedToDate = toDate.toISOString().split("T")[0];
  
    // Start building the payload
    const payload = {
      check_search_filters: {
        fullTableName: schemaTableName || "*.*",  
        connection: connectionName,             
        enabled: true                             
      },
      time_window_filter: {
        from_date: formattedFromDate,
        to_date: formattedToDate
      }
    };
  
    if (checkTarget) {
      payload.check_search_filters.checkTarget = checkTarget;
    }
  
    if (checkType) {
      payload.check_search_filters.checkType = checkType;
    }
  
    if (timeScale) {
      payload.check_search_filters.timeScale = timeScale;
    }
  
    axios
      .post(REACT_BASE_LOCAL_URL + "/api/jobs/runchecks?wait=false", payload)
      .then((response) => {
        console.log("Run checks successful:", response.data);
        setOpenRunChecksDialog(false);
  
        return axios.get(REACT_BASE_LOCAL_URL + "/api/jobs/jobs");
      })
      .then((fetchJobsResponse) => {
        setJobs(fetchJobsResponse.data.jobs);
      })
      .catch((error) => {
        console.error("Run checks or fetch jobs failed:", error);
      });
  
    setConnectionName("");
    setSchemaTableName("");
    setColumnName("");
    setColumnDatatype("");
    setColumnNullable(false);
    setCollectErrorSamples(false);
  };  

  const handleCloseRunChecksDialog = () => {
    setOpenRunChecksDialog(false);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const fetchJobs = () => {
    axios
      .get(REACT_BASE_LOCAL_URL + "/api/jobs/jobs")
      .then((response) => {
        setJobs(response.data.jobs);
      })
      .catch((error) => {
        console.error("Error fetching jobs data:", error);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const toggleNotification = () => {
    setOpenNotification(!openNotification);
    fetchJobs();
  };

  const toggleDetails = (jobId) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [jobId]: !prevState[jobId],
    }));
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString("sv-SE").replace("T", " ");
  };

  const getJobTypeText = (jobType) => {
    if (jobType === "run_scheduled_checks_cron")
      return "Run scheduled checks cron";
    if (jobType === "run_checks_on_table") return "Run checks on table";
    if (jobType === "run_checks") return "Run checks";
    if (jobType === "collect_statistics") return "Collect statistics";
    if (jobType === "collect_statistics_on_table")
      return "Collect statistics on table";
    if (jobType === "collect_statis") return "Collect statis";
    if (jobType === "synchronize_multiple_folders")
      return "Synchronize multiple folders";
    if (jobType === "synchronize_folder") return "Synchronize folders";
    if (jobType === "import_tables") return "Import tables";
    if (jobType === "synchronize_multiple_folders")
      return "Synchronize multiple folders";
    return jobType;
  };

  return (
    <div className="datasource-table-wrapper">
      <div className="table-header-wrapper">
        <h4>Added Data Sources({data.length})</h4>
        <div className="table-search-bar-wrapper">
          <div className="notification">
            <Badge
              badgeContent={jobs.length}
              onClick={toggleNotification}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ fontSize: "20px" }}>🔔</Typography>
            </Badge>
            {openNotification && (
              <div className="run-checks-notification">
                <div className="top-run-checks">
                  <h1>Notification:</h1>
                  <h1>{jobs.length}</h1>
                </div>
                <div className="bottom-run-checks">
                  {jobs.map((job) => (
                    <div className="run-checks-row" key={job.jobId.jobId}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid rgb(224 224 224)",
                          padding: "5px 0px",
                        }}
                      >
                        <div className="left-run-checks-row">
                          <p>{getJobTypeText(job.jobType)}</p>
                        </div>
                        <div className="right-run-checks-row">
                          <div className="run-check-status">
                            <div className="run-checks-valid">
                              <img src={right} alt="check" />
                            </div>
                            <p>{formatDate(job.jobId.createdAt)}</p>
                          </div>
                          <img
                            src={add}
                            alt="add details"
                            onClick={() => toggleDetails(job.jobId.jobId)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      {showDetails[job.jobId.jobId] && (
                        <div className="job-details">
                          <p>Status: {job.status}</p>
                          <p>Last changed: {formatDate(job.statusChangedAt)}</p>
                          {job.parameters?.runChecksOnTableParameters && (
                            <>
                              <p>
                                Highest severity:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result.highest_severity
                                }
                              </p>
                              <p>
                                Executed checks:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result.executed_checks
                                }
                              </p>
                              <p>
                                Valid results:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result.valid_results
                                }
                              </p>
                              <p>
                                Warnings:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result.warnings
                                }
                              </p>
                              <p>
                                Errors:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result.errors
                                }
                              </p>
                              <p>
                                Fatals:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result.fatals
                                }
                              </p>
                              <p>
                                Execution errors:{" "}
                                {
                                  job.parameters.runChecksOnTableParameters
                                    .run_checks_result.execution_errors
                                }
                              </p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="add-data-source-btn" onClick={toggleSidebar}>
            <img src={plusicon} alt="+" />
            <p>Add Data Source</p>
          </div>
        </div>
      </div>
      <div className="columns-name">
        <div>Connection Name</div>
        <div>Provider Type</div>
        <div>Hostname</div>
        <div>Port</div>
        <div>Database Name</div>
        <div>Username</div>
        <div>Sensitivity</div>
        <div>Action</div>
      </div>
      {data.map((value, index) => (
        <div
          key={index}
          className="table-data"
          onClick={() =>
            handleDataSetFlow(value.connection_hash, value.connection_name)
          }
        >
          <div className="table-row">
            <div>{value.connection_name}</div>
            <div>{value.provider_type}</div>
            <div>{value.postgresql.host}</div>
            <div>{value.postgresql.port || "N/A"}</div>
            <div>{value.postgresql.database}</div>
            <div>{value.postgresql.user}</div>
            <div className={getSensitivityColorClass("high")}>high</div>
            <div className="column-action">
              <Dropdown>
                <MenuButton
                  slots={{ root: IconButton }}
                  slotProps={{
                    root: { variant: "outlined", color: "neutral" },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <MoreVert />
                </MenuButton>
                <Menu style={{ width: "200px", padding: "15px" }}>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setConnectionName(value.connection_name);
                      setOpenRunChecksDialog(true);
                    }}
                  >
                    Run checks
                  </MenuItem>
                  <MenuItem>Collect statistics</MenuItem>
                  <MenuItem>Import Metadata</MenuItem>
                  <MenuItem>Add schema</MenuItem>
                  <MenuItem>Copy name</MenuItem>
                  <MenuItem>Refresh</MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteRow(value.connection_name);
                    }}
                  >
                    Delete connection
                  </MenuItem>
                  <MenuItem>Delete Data</MenuItem>
                </Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      ))}
      <Dialog
        open={openRunChecksDialog}
        onClose={handleCloseRunChecksDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "1.5rem", color: "#3c3c3c" }}
        >
          Run checks
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", marginTop: "8px" }}
          >
            Connection
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            fullWidth
            value={connectionName}
            onChange={(e) => setConnectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            onClick={handleCloseRunChecksDialog}
            variant="outlined"
            sx={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button onClick={handleRunChecks} variant="contained" color="primary">
            Run checks
          </Button>
        </DialogActions>
      </Dialog>

      <div className={`sidebar ${isOpen ? "active" : ""}`}>
        <div className="sd-header">
          <h3>Add Data source</h3>
          <div className="sidebar-cross-btn" onClick={toggleSidebar}>
            <img src={crossicon} alt="crossicon" />
          </div>
        </div>
        <div className="sd-body">
          <DataSourceForm onClose={toggleSidebar} />
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen ? "active" : ""}`}
        onClick={toggleSidebar}
      ></div>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        sx={{ "& .MuiDialog-paper": { padding: "20px", borderRadius: "12px" } }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#f5f5f5",
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Confirm Deletion
        </DialogTitle>
        <DialogContent sx={{ padding: "16px" }}>
          <DialogContentText
            sx={{ fontSize: "1rem", color: "#666", marginBottom: "16px" }}
          >
            Are you sure you want to remove connection "{selectedConnectionName}
            "?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "flex-end", padding: "16px" }}
        >
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{
              backgroundColor: "#f5f5f5",
              color: "#333",
              fontWeight: "bold",
              marginRight: "8px",
              padding: "8px 16px",
              borderRadius: "4px",
              "&:hover": { backgroundColor: "#e0e0e0" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            sx={{
              backgroundColor: "#d32f2f",
              color: "white",
              fontWeight: "bold",
              padding: "8px 16px",
              borderRadius: "4px",
              "&:hover": { backgroundColor: "#c62828" },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Run Checks */}
      <Dialog
        open={openRunChecksDialog}
        onClose={handleCloseRunChecksDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "1.5rem", color: "#3c3c3c" }}
        >
          Run checks
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", marginTop: "8px" }}
          >
            Connection
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            fullWidth
            value={connectionName}
            onChange={(e) => setConnectionName(e.target.value)}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", flexDirection: "column", width: "30%" }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", marginTop: "12px" }}
              >
                Schema and table name
              </Typography>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                value={schemaTableName || "*.*"}
                onChange={(e) => setSchemaTableName(e.target.value)}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "30%" }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", marginTop: "12px" }}
              >
                Column name
              </Typography>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                value={columnName || "*"}
                onChange={(e) => setColumnName(e.target.value)}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "30%" }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", marginTop: "12px" }}
              >
                Column datatype
              </Typography>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                value={columnDatatype || "*"}
                onChange={(e) => setColumnDatatype(e.target.value)}
              />
            </div>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={columnNullable}
                onChange={(e) => setColumnNullable(e.target.checked)}
              />
            }
            label="Column nullable"
            sx={{ marginTop: "16px" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={collectErrorSamples}
                onChange={(e) => setCollectErrorSamples(e.target.checked)}
              />
            }
            label="Collect error samples"
            sx={{ marginTop: "16px" }}
          />
          <Divider sx={{ margin: "16px 0" }} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", flexDirection: "column", width: "30%" }}
            >
              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel>Check Target</InputLabel>
                <Select
                  native
                  value={checkTarget}
                  onChange={(e) => setCheckTarget(e.target.value)}
                  input={<OutlinedInput label="Check Target" />}
                  startAdornment={
                    <InputAdornment position="start">
                      <CheckIcon />
                    </InputAdornment>
                  }
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "12px 14px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "grey",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "blue",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "blue",
                    },
                  }}
                >
                  <option value="" disabled>
                    Check Target
                  </option>
                  <option
                    value="table"
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Table
                  </option>
                  <option
                    value="column"
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Column
                  </option>
                </Select>
              </FormControl>
            </div>

            {/* Check Type */}
            <div
              style={{ display: "flex", flexDirection: "column", width: "30%" }}
            >
              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel>Check Type</InputLabel>
                <Select
                  native
                  value={checkType}
                  onChange={(e) => setCheckType(e.target.value)}
                  input={<OutlinedInput label="Check Type" />}
                  startAdornment={
                    <InputAdornment position="start">
                      <SettingsIcon />
                    </InputAdornment>
                  }
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "12px 14px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "grey",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "blue",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "blue",
                    },
                  }}
                >
                  <option value="" disabled>
                    Check Type
                  </option>
                  <option
                    value="profiling"
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Profiling
                  </option>
                  <option
                    value="monitoring"
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Monitoring
                  </option>
                  <option
                    value="partitioned"
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Partitioned
                  </option>
                </Select>
              </FormControl>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "30%" }}
            >
              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel>Time Scale</InputLabel>
                <Select
                  native
                  value={timeScale}
                  onChange={(e) => setTimeScale(e.target.value)}
                  input={<OutlinedInput label="Time Scale" />}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccessTimeIcon />
                    </InputAdornment>
                  }
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "12px 14px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "grey",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "blue",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "blue",
                    },
                  }}
                >
                  <option value="" disabled>
                    Time Scale
                  </option>
                  <option
                    value="daily"
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Daily
                  </option>
                  <option
                    value="monthly"
                    style={{ padding: "5px 10px", cursor: "pointer" }}
                  >
                    Monthly
                  </option>
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            onClick={handleCloseRunChecksDialog}
            variant="outlined"
            sx={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button onClick={handleRunChecks} variant="contained" color="primary">
            Run checks
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function getSensitivityColorClass(sensitivity) {
  if (sensitivity === "high") return "high-sensitivity";
  if (sensitivity === "medium") return "medium-sensitivity";
  if (sensitivity === "low") return "low-sensitivity";
  return "";
}
